import { Button, Divider, Flex, Layout, Popover, Row, Select, Tag, Tooltip, Typography } from "antd";
import { CopyrightOutlined } from "@ant-design/icons";
import { useState } from "react";
import footerCss from "./style.module.scss";
import { TweenOneGroup } from "rc-tween-one";
import { AppDispatch, RootState } from "../../../store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { openItem, removeItemInList } from "../../../slices/minimizeSlice";
import { MinList } from "../../../slices/taskSlice";
import { TfiMenuAlt } from "react-icons/tfi";
import { FaAngleRight } from "react-icons/fa6";
import { IoEllipsisHorizontal } from "react-icons/io5";

const _ = require("lodash");

const { Footer } = Layout;

const options = [
    { value: "English", label: "English" },
    { value: "Turkish", label: "Turkish" },
];

function FooterLayout() {
    const { minimizeItemList } = useSelector((state: RootState) => state.minimize);
    const dispatch = useDispatch<AppDispatch>();
    const [selectedLanguage, setSelectedLanguage] = useState("English");

    const handleChange = (value: string) => {
        setSelectedLanguage(value);
    };

    const handleClose = (removedTag: any) => {
        dispatch(removeItemInList(removedTag._id));
    };

    const taskTagClick = (id: string, type: "TASK" | "CONTACT", name: string) => {
        dispatch(
            openItem({
                _id: id,
                type,
                name,
            })
        );
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Footer className={footerCss.footer}>
                <Row justify="space-between" align={"middle"}>
                    <Flex align="center" justify="space-between" gap={10} className={footerCss.footerSelect}>
                        <Button style={{ padding: "7px 14.5px", height: "auto", width: "auto" }} type="default">
                            ATULAM
                        </Button>
                        <Select value={selectedLanguage} style={{ width: 104 }} onChange={handleChange} options={options} />
                        <Flex align="center">
                            <CopyrightOutlined style={{ fontSize: 18 }} />
                            <Typography style={{ paddingLeft: "5px" }}>2024 ATULAM</Typography>
                        </Flex>
                        <Divider type="vertical" />
                        <Typography>Implementation request</Typography>
                        <Divider type="vertical" />
                        <Typography>Themes</Typography>
                        <Divider type="vertical" />
                        <Typography>Print</Typography>
                    </Flex>

                    {minimizeItemList && minimizeItemList?.length > 0 && (
                        <div className={footerCss.rightFooter}>
                            <div className={`menu-content ${isOpen ? "open" : ""}`}>
                                <span className="arrow-icon" onClick={toggleMenu}>
                                    <FaAngleRight size={18} color="#6f7994" />
                                </span>
                                <TweenOneGroup
                                    appear={true}
                                    enter={{ scale: 0.8, opacity: 0, type: "from", duration: 100 }}
                                    leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                                    onEnd={(e: any) => {
                                        if (e.type === "appear" || e.type === "enter") {
                                            (e.target as any).style = "display: inline-block";
                                        }
                                    }}
                                >
                                    {minimizeItemList?.slice(0, 3).map((opt: MinList) => {
                                        return (
                                            <span key={opt._id} style={{ display: "inline-block" }}>
                                                <Tooltip title={_.capitalize(opt.type)}>
                                                    <Tag
                                                        closable
                                                        onClose={(e) => {
                                                            e.preventDefault();
                                                            handleClose(opt);
                                                        }}
                                                        onClick={() => taskTagClick(opt._id, opt.type, opt.name)}
                                                        className={footerCss.minimizeTag}
                                                    >
                                                        <Typography.Text style={{ color: "#828b95", fontSize: 12, width: 100 }} ellipsis>
                                                            {opt.name}
                                                        </Typography.Text>
                                                    </Tag>
                                                </Tooltip>
                                            </span>
                                        );
                                    })}
                                </TweenOneGroup>
                            </div>
                            {minimizeItemList.length > 3 && (
                                <Popover
                                    content={minimizeItemList.slice(3).map((opt: MinList) => (
                                        <Flex style={{ paddingBottom: "4px" }}>
                                            <Tooltip title={_.capitalize(opt.type)}>
                                                <Tag
                                                    closable
                                                    onClose={(e) => {
                                                        e.preventDefault();
                                                        handleClose(opt);
                                                    }}
                                                    onClick={() => taskTagClick(opt._id, opt.type, opt.name)}
                                                    className={footerCss.minimizeTag}
                                                >
                                                    <Typography.Text style={{ color: "#828b95", fontSize: 12, maxWidth: 150 }} ellipsis>
                                                        {opt.name}
                                                    </Typography.Text>
                                                </Tag>
                                            </Tooltip>
                                        </Flex>
                                    ))}
                                    trigger="click"
                                    placement="top"
                                >
                                    <span className="minCloseBtn" style={{ marginRight: "10px" }}>
                                        <IoEllipsisHorizontal size={18} color="#6f7994" />
                                    </span>
                                </Popover>
                            )}
                            <span className="minCloseBtn" onClick={toggleMenu}>
                                <TfiMenuAlt size={18} color="#6f7994" />
                            </span>
                        </div>
                    )}
                </Row>
            </Footer>
        </>
    );
}

export default FooterLayout;
