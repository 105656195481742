import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";

export const addPassenger = createAsyncThunk("passengers/create", async (newPassenger: any, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post("/passenger/create", newPassenger);
        return response?.data?.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data);
    }
});

export const fetchPassengerList = createAsyncThunk("passenger/list", async (_, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get("/passenger/list");
        return response?.data?.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || error.message);
    }
});

export const fetchReferenceList = createAsyncThunk("passenger/ref-list", async (_, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get("/passenger/ref-list");
        return response?.data?.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || error.message);
    }
});

export const updatePassengerType = createAsyncThunk("passenger/type", async ({ id, type }: { id: string; type: string }, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.put("/passenger/update-type", { id, type });
        return response?.data?.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || error.message);
    }
});

export const getGroupDetails = createAsyncThunk("passenger/get", async (id: string, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`/passenger/get/${id}`);
        return response?.data?.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || error.message);
    }
});

export const updatePassengerList = createAsyncThunk("passenger/update", async ({ id, updatedData }: { id: string; updatedData: any }, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.put(`/passenger/update/${id}`, updatedData);
        return response?.data?.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || error.message);
    }
});

export interface DocumentInterface {
    doc_type: string;
    file_name: string;
    url?: string;
    exp_date?: string;
    _id?: string;
    doc_name?: string;
    upload_date?: string;
}
export interface ContactDocumentInterface {
    passport: {
        back_image?: DocumentInterface;
        date_of_birth?: string;
        father_name?: string;
        first_name?: string;
        front_image?: DocumentInterface;
        gender?: "Male" | "Female";
        last_name?: string;
        middle_name?: string;
        mother_name?: string;
        passport_exp_date?: string;
        passport_history?: DocumentInterface[];
        passport_issue_date?: string;
        passport_number?: string;
        spouse_name?: string;
    };
    pan_card?: {
        image?: DocumentInterface;
        name?: string;
        pan_number?: string;
    };
    other_documents?: DocumentInterface[];
}
export interface ContactDataInterface {
    _id?: string;
    contact_name: string;
    contact_number: string;
    full_name: string;
    type?: "REGULAR" | "PREMIUM";
    documents?: ContactDocumentInterface;
    email?: string;
    address?: {
        city?: string;
        country?: string;
        pincode?: string;
        residents_address?: string;
        state?: string;
    };
    is_main_person?: boolean;
    profile_image?: string;
    reference_by?: string | ContactDataInterface;
}

export interface GroupDataInterface {
    _id: string;
    main_person: ContactDataInterface;
    group_member: ContactDataInterface[];
}
interface IPersonImage {
    [key: string]: any;
}
interface IFormData {
    [formId: number]: any;
}
interface ReferenceInterface {
    _id: string;
    full_name: string;
    contact_name: string;
}

interface IMobileNumber {
    [fromId: string]: string;
}

interface IPassportLoading {
        front: boolean;
        back: boolean;
    };


interface ContactState {
    profileImage: IPersonImage;
    passPortImage: any;
    passPortHistory: any;
    panCardImage: any;
    formData: IFormData;
    mobileNumber: string | null;
    referenceBy: string | null;
    otherDocHistory: any;
    loading: any;
    error: string | null;
    allContactList: ContactDataInterface[];
    referenceList: ReferenceInterface[];
    groupData: GroupDataInterface | null;
    isPassportUploading: IPassportLoading
}

const initialState: ContactState = {
    profileImage: {},
    passPortImage: {},
    passPortHistory: {},
    panCardImage: {},
    otherDocHistory: {},
    formData: {},
    mobileNumber: null,
    referenceBy: null,
    loading: false,
    error: null,
    allContactList: [],
    referenceList: [],
    groupData: null,
    isPassportUploading: {
        front: false,
        back: false
    },
};

const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        setProfileImage: (state, action: PayloadAction<{ formId: string; images: any }>) => {
            state.profileImage[action.payload.formId] = action.payload.images;
        },
        resetProfileImage: (state) => {
            state.profileImage = {};
        },
        setPassPortImage: (state, action: PayloadAction<{ formId: string; images: any; type: "front" | "back" }>) => {
            const { formId, images, type } = action.payload;
            if (!state.passPortImage[formId]) {
                state.passPortImage[formId] = {};
            }
            state.passPortImage[formId][type] = images;
        },
        setPassPortHistory: (state, action: PayloadAction<{ formId: string; newPassPort: any }>) => {
            const { formId, newPassPort } = action.payload;
            if (!state.passPortHistory[formId]) {
                state.passPortHistory[formId] = { history: [] };
            }
            state.passPortHistory[formId].history.push(newPassPort);
        },
        setInitPassPortHistory: (state, action: PayloadAction<{ formId: string; data: any }>) => {
            const { formId, data } = action.payload;
            if (!state.passPortHistory[formId]) {
                state.passPortHistory[formId] = {};
            }

            state.passPortHistory[formId].history = data;
        },
        deletePassPortHistory: (state, action: PayloadAction<{ formId: string; itemId: string }>) => {
            const { formId, itemId } = action.payload;
            if (state.passPortHistory[formId]?.history) {
                state.passPortHistory[formId].history = state.passPortHistory[formId].history.filter((item: any) => item.id !== itemId);
            }
        },
        resetPassPortHistory: (state) => {
            state.passPortHistory = {};
        },
        setPanCardImage: (state, action: PayloadAction<{ formId: string; images: any }>) => {
            state.panCardImage[action.payload.formId] = action.payload.images;
        },
        setOtherDocHistory: (state, action: PayloadAction<{ formId: string; newDoc: any }>) => {
            const { formId, newDoc } = action.payload;
            if (!state.otherDocHistory[formId]) {
                state.otherDocHistory[formId] = { history: [] };
            }
            state.otherDocHistory[formId].history.push(newDoc);
        },
        setInitDocHistory: (state, action: PayloadAction<{ formId: string; data: any }>) => {
            const { formId, data } = action.payload;
            if (!state.otherDocHistory[formId]) {
                state.otherDocHistory[formId] = {};
            }
            state.otherDocHistory[formId].history = data;
        },
        deleteOtherDocHistory: (state, action: PayloadAction<{ formId: string; itemId: string }>) => {
            const { formId, itemId } = action.payload;
            if (state.otherDocHistory[formId]?.history) {
                state.otherDocHistory[formId].history = state.otherDocHistory[formId].history.filter((item: any) => item.id !== itemId);
            }
        },
        setFormData: (state, action: PayloadAction<any>) => {
            state.formData = action.payload;
        },
        setMobileNumber: (state, action: PayloadAction<string>) => {
            state.mobileNumber = action.payload;
        },
        setReferenceBy: (state, action: PayloadAction<string>) => {
            state.referenceBy = action.payload;
        },
        setIsPassportUploading: (state, action:PayloadAction<{ type: "front" | "back" ; isLoading: boolean }> ) => {
            const { type, isLoading } = action.payload;
            state.isPassportUploading[type] = isLoading;
        },
        resetAllFormState: (state) => {
            state.profileImage = {};
            state.passPortImage = {};
            state.passPortHistory = {};
            state.panCardImage = {};
            state.otherDocHistory = {};
            state.formData = {};
            state.mobileNumber = null;
            state.referenceBy = null;
            state.groupData = null;
        },
    },
    extraReducers: (builder) => {
        // create contact list
        builder.addCase(addPassenger.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addPassenger.fulfilled, (state, action) => {
            state.loading = false;
            state.allContactList.push(action.payload);
        });
        builder.addCase(addPassenger.rejected, (state, action) => {
            state.loading = false;
            const errData: any = action.payload;
            state.error = errData.message as string;
        });
        builder.addCase(fetchPassengerList.fulfilled, (state, action) => {
            state.loading = false;
            state.allContactList = action.payload;
        });
        builder.addCase(updatePassengerType.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updatePassengerType.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(updatePassengerType.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getGroupDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.groupData = action.payload;
        });

        builder.addCase(updatePassengerList.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(fetchReferenceList.fulfilled, (state, action) => {
            state.loading = false;
            state.referenceList = action.payload;
        });
    },
});

export const {
    setProfileImage,
    resetProfileImage,
    setPassPortImage,
    setPassPortHistory,
    deletePassPortHistory,
    setPanCardImage,
    setOtherDocHistory,
    deleteOtherDocHistory,
    setFormData,
    resetAllFormState,
    setInitPassPortHistory,
    setInitDocHistory,
    setMobileNumber,
    setReferenceBy,
    setIsPassportUploading
} = contactSlice.actions;
export default contactSlice.reducer;
