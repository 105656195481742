import { Form, Radio } from "antd";
import React from "react";
export interface OpiotnsInterface {
    value: string;
    label: string;
}
export interface InputRadioPropsInterface {
    onChange?: (val: any) => void;
    options: OpiotnsInterface[];
    label?: string;
    name: any;
    className: string;
}

const InputRadio: React.FC<InputRadioPropsInterface> = ({ onChange, options, ...rest }) => {
    return (
        <>
            <Form.Item {...rest}>
                <Radio.Group onChange={onChange}>
                    {options?.map((opt) => (
                        <Radio value={opt?.value} key={opt?.label}>
                            {opt?.label}
                        </Radio>
                    ))}
                </Radio.Group>
            </Form.Item>
        </>
    );
};

export default InputRadio;
