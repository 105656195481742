import { Tabs } from "antd";
import styleCss from "./style.module.scss";
import { ReactNode } from "react";

interface TabsComponentProps {
    items: Array<{ key: string; label: string; children: ReactNode; className?: string }>;
    onChange?: (key: string) => void;
    activeKey?: string;
    defaultActiveKey?: string;
}

const { TabPane } = Tabs;

const NavTab = ({ items, onChange, activeKey, defaultActiveKey }: TabsComponentProps) => {
    return (
        <Tabs className={styleCss.customNavTab} onChange={onChange} activeKey={activeKey} defaultActiveKey={defaultActiveKey}>
            {items.map((item) => (
                <TabPane tab={<span className={`${item.className ? item.className : ""}`}>{item.label}</span>} key={item.key}>
                    {item.children}
                </TabPane>
            ))}
        </Tabs>
    );
};

export default NavTab;
