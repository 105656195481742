import React, { useEffect, useState } from "react";
import { Avatar, Button, Flex, Image, Select, Space, Table, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchPassengerList, fetchReferenceList, updatePassengerType } from "../../slices/contactSlice";
import { getDocumentURL } from "../../utils/AWSService";
import SearchHeader from "../../components/SearchHeader";
import DataTable from "../../components/DataTable";
import passportIcon from "../../assets/passport-enb.png";
import panCard from "../../assets/pancard-enb.png";
// import adharCard from "../../assets/adharcard-enb.png";
import docIcon from "../../assets/docIcon.svg";
import { UserOutlined } from "@ant-design/icons";
import styleCss from "./style.module.scss";
import { setContactDrawerOpen, setContactOpenId } from "../../slices/layoutSlice";
import { DRAWER_TYPE } from "../../utils/const";
import { FaChevronDown, FaChevronRight } from "react-icons/fa6";
import { openItem } from "../../slices/minimizeSlice";
import CreateContact from "./CreateContact";
const _ = require("lodash");

function ContactList() {
    const dispatch = useDispatch<AppDispatch>();
    const { allContactList } = useSelector((state: RootState) => state.contact);
    const { user } = useSelector((state: RootState) => state.user);
    const [searchKeyword, setSearchKeyword] = useState("");

    useEffect(() => {
        dispatch(fetchPassengerList());
        dispatch(fetchReferenceList());
    }, []);

    const handleChange = async (val: string, id: string) => {
        await dispatch(updatePassengerType({ id, type: val }));
        await dispatch(fetchPassengerList());
    };

    const getImageUrl = (passengerId: string, fileName: string) => {
        return getDocumentURL(`passenger_doc/${user?.tenant_id}/${passengerId}/${fileName}`) ?? false;
    };

    const handelPreview = (id: string, fileName: string) => {
        const url = getImageUrl(id, fileName);
        if (url) {
            window.open(url, "_blank");
        }
    };

    const column: any[] = [
        Table.SELECTION_COLUMN,
        Table.EXPAND_COLUMN,
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (val: any, record: any) => {
                const url = getImageUrl(record.key, record?.profile);
                return (
                    <Space size={10}>
                        {!record?.profile ? (
                            <Avatar size={28} icon={<UserOutlined />} />
                        ) : (
                            <Image src={`${url}`} preview={false} height={28} width={28} style={{ borderRadius: "50%", objectFit: "cover" }} />
                        )}
                        <span>{val}</span>
                    </Space>
                );
            },
            onCell: (record: any) => {
                return {
                    onClick: () => {
                        contactOpenHandle(record);
                    },
                };
            },
        },
        {
            title: "Contact Number",
            dataIndex: "contactNumber",
            key: "contactNumber",
            width: "180px",
            onCell: (record: any) => {
                return {
                    onClick: () => {
                        contactOpenHandle(record);
                    },
                };
            },
        },
        {
            title: "Reference by",
            dataIndex: "ref",
            key: "ref",
            onCell: (record: any) => {
                return {
                    onClick: () => {
                        contactOpenHandle(record);
                    },
                };
            },
        },
        {
            title: "Tag",
            dataIndex: "tag",
            key: "tag",
            render: (item: any, record: any) => {
                return (
                    <div
                        className="status"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Select
                            size="small"
                            onChange={(e) => handleChange(e, record.key)}
                            value={item}
                            options={[
                                { value: "REGULAR", label: "Regular" },
                                { value: "PREMIUM", label: "Premium" },
                            ]}
                            className={`${styleCss.contactLabel} ${item === "REGULAR" ? styleCss.red : styleCss.green}`}
                            popupClassName="contactTag"
                            suffixIcon={null}
                            variant="borderless"
                            style={{ width: "100px" }}
                        />
                    </div>
                );
            },
            width: "100px",
        },
        {
            title: "Document",
            dataIndex: "doc",
            key: "doc",
            render: (item: any, record: any) => {
                const { other_documents, pan_card, passport } = item;
                const { key } = record;

                return (
                    <>
                        {!passport?.front_image && !pan_card?.image && !other_documents?.length ? (
                            <>
                                <Typography.Link>Upload</Typography.Link>
                            </>
                        ) : (
                            <Flex gap={8}>
                                {passport?.front_image && Object?.keys(passport?.front_image).length > 0 && (
                                    <Image.PreviewGroup>
                                        <Image
                                            src={`${getImageUrl(key, passport?.front_image?.file_name)}`}
                                            //   onClick={() => handelPreview(key, passport?.front_image?.file_name)}
                                            height={26}
                                            width={42}
                                            style={{ objectFit: "cover" }}
                                        />
                                        {passport?.back_image && (
                                            <Image
                                                src={`${getImageUrl(key, passport?.back_image?.file_name)}`}
                                                //   onClick={() => handelPreview(key, passport?.back_image?.file_name)}
                                                height={26}
                                                width={42}
                                                style={{ objectFit: "cover", display: "none" }}
                                                rootClassName="passport-back-list"
                                            />
                                        )}
                                    </Image.PreviewGroup>
                                )}
                                {pan_card?.image && (
                                    <Image
                                        src={`${getImageUrl(key, pan_card?.image?.file_name)}`}
                                        preview={false}
                                        onClick={() => handelPreview(key, pan_card?.image?.file_name)}
                                        height={26}
                                        width={42}
                                        style={{ objectFit: "cover" }}
                                    />
                                )}
                                {/* <Image src={adharCard} preview={false} /> */}
                                {other_documents?.length >= 1 && <Image src={docIcon} preview={false} />}
                            </Flex>
                        )}
                    </>
                );
            },
            width: "180px",
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
        },
        getCheckboxProps: (record: any) => {},
    };

    const formattedData = _.map(allContactList, (item: any) => {
        const mainPerson = _.get(item, "main_person", {});
        const groupMembers = _.get(item, "group_member", []);

        const formatDocuments = (docs: any) =>
            _.defaults(docs, {
                other_documents: [],
                pan_card: {},
                passport: [],
            });

        return {
            key: mainPerson._id,
            name: mainPerson.contact_name,
            contactNumber: mainPerson.contact_number,
            ref: _.get(mainPerson, "reference_by.contact_name", "-"),
            tag: mainPerson.type,
            doc: formatDocuments(mainPerson.documents),
            profile: mainPerson.profile_image,
            children: _.map(groupMembers, (member: any) => ({
                key: member?._id,
                name: member?.contact_name,
                contactNumber: member?.contact_number,
                ref: _.get(member, "reference_by.contact_name", "-"),
                tag: member?.type,
                doc: formatDocuments(member?.documents),
                profile: member?.profile_image,
            })),
        };
    });
    const openCreateModel = () => {
        dispatch(setContactDrawerOpen(DRAWER_TYPE.CONTACT_DRAWER));
    };
    const contactOpenHandle = (value: any) => {
        dispatch(
            openItem({
                _id: value.key,
                name: value.name,
                type: "CONTACT",
            })
        );
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(e.target.value);
    };

    const filterData = (data: any[], keyword: string) => {
        if (!keyword) {
            return data;
        }

        return data.filter((item) => {
            const mainPersonMatches = item.name.toLowerCase().includes(keyword.toLowerCase());
            const groupPersonMatches = item.children?.some((child: any) => child.name.toLowerCase().includes(keyword.toLowerCase()));

            return mainPersonMatches || groupPersonMatches;
        });
    };

    const filteredData = filterData(formattedData, searchKeyword);

    return (
        <div>
            <SearchHeader title={"Passenger List"} searchValue={searchKeyword} onSearchChange={handleSearchChange}>
                <Button onClick={openCreateModel} className={styleCss.contactBtn}>
                    CREATE
                </Button>
            </SearchHeader>
            <CreateContact />
            <div style={{ marginTop: 15, cursor: "pointer" }}>
                <DataTable
                    column={column}
                    data={filteredData}
                    rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                    }}
                    expandable={{
                        expandIcon: ({ expanded, onExpand, record }: any) =>
                            record?.children?.length > 0 ? (
                                expanded ? (
                                    <FaChevronDown onClick={(e) => onExpand(record, e)} />
                                ) : (
                                    <FaChevronRight onClick={(e) => onExpand(record, e)} />
                                )
                            ) : null,
                        rowExpandable: (record: any) => !!record.children,
                        expandedRowRender: () => {
                            return null;
                        },
                    }}
                    pagination={false}
                />
            </div>
        </div>
    );
}

export default ContactList;
