import { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Flex,
    Form,
    GetProp,
    Image,
    Input,
    List,
    Popover,
    Row,
    Select,
    Skeleton,
    Tabs,
    Tag,
    Typography,
    Upload,
    UploadFile,
    UploadProps,
} from "antd";
import { CaretDownOutlined, CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined, SyncOutlined, UserOutlined } from "@ant-design/icons";
import { AppDispatch, RootState } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import Search from "antd/es/input/Search";
import dayjs from "dayjs";
import fireActiveIcon from "../../../assets/fireactive.svg";
import fireIcon from "../../../assets/fire.svg";
import webSocketService from "../../../utils/websocketService";
import AxiosService from "../../../utils/APIService";
import { DRAWER_TYPE, INQUIRY_TYPE, INQUIRY_TYPE_STATUS } from "../../../utils/const";
import { capitalizeFirstLetter, capitalizeFirstLetterOfWord, getBase64, getInitials } from "../../../utils/commonFunctions";
import useTeamMember from "../../../hooks/useTeamMember";
import CheckList from "./CheckList";
import duration from "dayjs/plugin/duration";
import HistoryBlock from "../History";
import { InputBox } from "../../../components/FormInput";
import { subTaskDataListInterface } from "./CreateTask";
import { UserInvite } from "../../../components/UserInviteModel";
import { setDrawerOpen } from "../../../slices/layoutSlice";
import TaskStyleCss from "./style.module.scss";
import { downloadDocument } from "../../../utils/AWSService";
import FileListItem from "../../../components/FileListItem";
import { addItemInList, closeItem } from "../../../slices/minimizeSlice";
import CommentsBlock from "../CommentsBlock";

dayjs.extend(duration);
const { Text } = Typography;

const calculateDaysRemaining = (dueDate: string) => {
    if (!dueDate) return null;

    const [datePart, timePart] = dueDate.split(" ");
    const [day, month, year] = datePart.split("-");
    const [hours, minutes] = timePart.split(":");
    const due = new Date(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes));

    const today = new Date();
    const differenceInTime = due.getTime() - today.getTime();

    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
};

interface TaskDetailsInterface {
    id: string;
    layer?: number;
}

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const minimizeArrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
        <path
            fill="#525C69"
            d="M7.383 4.32a.686.686 0 0 0-.971 0l-.768.767a.686.686 0 0 0 0 .97l6.633 6.634H7.21a.686.686 0 0 0-.686.687v.882c0 .38.307.687.686.687h8.43a.689.689 0 0 0 .686-.687V5.831a.687.687 0 0 0-.686-.687h-1.132a.686.686 0 0 0-.687.687v4.926L7.383 4.319ZM5.987 17.715a.687.687 0 0 0-.687.687v.883c0 .379.308.686.687.686h12.327c.379 0 .686-.307.686-.686v-.883a.687.687 0 0 0-.686-.687H5.987Z"
        />
    </svg>
);

const colors = ["#76a3da"];

function TaskDetails({ id, layer }: TaskDetailsInterface) {
    const { user } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();

    const apiService = new AxiosService();
    const { assignList, fetchAssignList } = useTeamMember();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [taskData, setTaskData] = useState<any>(null);
    const [showAll, setShowAll] = useState(false);
    const [typeEditVisible, setTypeEditVisible] = useState(false);
    const [isPrior, setIsPrior] = useState(false);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showUpload, setShowUpload] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [subTaskData, setSubTaskData] = useState<subTaskDataListInterface[]>([]);
    const [note, setNote] = useState("");
    const [syncing, setSyncing] = useState(false);
    const [saved, setSaved] = useState(false);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [modelShow, setModelShow] = useState(true);
    const { openItemList } = useSelector((state: RootState) => state.minimize);
    const currentOpen = openItemList[openItemList.length - 1];
    useEffect(() => {
        if (id) {
            fetchTaskData(id);
        }
        return () => {
            setTaskData(null);
        };
    }, [id]);

    useEffect(() => {
        const handleTaskUpdate = (val: any) => {
            if (val.tenantId === user?.tenant_id && val?.task?._id === id) {
                setTaskData((prev: any) => ({ ...prev, ...val.task }));
                setSyncing(true);
                setSaved(false);
                setSubTaskData(val.task.subtask);
                setTimeout(() => {
                    setSyncing(false);
                    setSaved(true);
                    setTimeout(() => setSaved(false), 2000);
                }, 1000);
            }
        };
        webSocketService.onMessage("taskUpdate", handleTaskUpdate);
    }, [user, id]);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (note !== taskData?.note) {
                webSocketService.sendMessage("taskNoteUpdate", {
                    tntId: user?.tenant_id,
                    taskId: taskData?._id,
                    note: capitalizeFirstLetter(note),
                });
            }
        }, 2000);
        return () => {
            clearTimeout(handler);
        };
    }, [note]);

    useEffect(() => {
        if (taskData) {
            taskForm.setFieldsValue({
                title: taskData.title,
                notes: taskData.note,
            });
            setSelectedTypes(taskData?.task_type?.map((type: any) => type));
        }
    }, [taskData]);

    useEffect(() => {
        fetchAssignList();
    }, []);

    const handleShowMore = () => {
        setShowAll(!showAll);
    };

    const handleChange: UploadProps["onChange"] = ({ fileList: newFileList, file }: any) => {
        if (!file.hasOwnProperty("status")) {
            setSyncing(true);
            setSaved(false);
            const formData = new FormData();
            formData.append("file", file);
            apiService
                .put(`/task/add-doc/${taskData?._id}`, formData)
                .then((res: any) => {
                    let docList = [...fileList];
                    docList.push({
                        uid: `-${fileList.length + 1}`,
                        name: res?.data?.fileName,
                        status: "done",
                        url: res.data.url,
                    });
                    setFileList(docList);
                    setSyncing(false);
                    setSaved(true);
                    setTimeout(() => setSaved(false), 2000);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    const fetchTaskData = (taskId: string) => {
        setLoading(true);
        apiService
            .get(`/task/get/${taskId}`, {}, false)
            .then((res: any) => {
                const data = res?.data;
                taskForm.setFieldValue("title", data?.title);
                taskForm.setFieldValue("is_prior", data?.is_high_prior);
                taskForm.setFieldValue("notes", data?.note);
                let docList = data?.documents?.map((dc: any, i: number) => ({
                    uid: `${0 - i}`,
                    name: dc.name,
                    status: "done",
                    url: dc.url,
                }));
                setFileList(docList);
                setTaskData(data);
                setLoading(false);
                setIsPrior(data?.is_high_prior);
                if (data?.subtask?.length > 0) {
                    setSubTaskData(data.subtask);
                    setShowUpload("CHECKLIST");
                }
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };

    const [taskForm] = Form.useForm();

    const handleStatusChange = (val: string) => {
        webSocketService.sendMessage("taskStatusUpdate", {
            tntId: user?.tenant_id,
            taskId: id,
            userId: user?._id,
            status: val,
        });
    };

    const handleTypeSearch = (e: any) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredInquiryTypes = Object.entries(INQUIRY_TYPE).map(([key, value]) => {
        const title = (value as { title: string }).title;
        return [key, title];
    });

    const handleTypeVisibleChange = (visible: boolean) => {
        setTypeEditVisible(visible);
    };

    const daysRemaining = calculateDaysRemaining(taskData?.due_date);

    const handleTitleBlur = (e: any) => {
        setIsEditingTitle(false);
        webSocketService.sendMessage("taskTitleChange", {
            tntId: user?.tenant_id,
            taskId: taskData?._id,
            title: capitalizeFirstLetterOfWord(e.target.value),
            userId: user?._id,
        });
    };

    const noteUpdate = (e: any) => {
        webSocketService.sendMessage("taskNoteUpdate", {
            tntId: user?.tenant_id,
            taskId: taskData?._id,
            note: capitalizeFirstLetter(e.target.value),
        });
    };

    const handleNoteChange = (e: any) => {
        setNote(e.target.value);
    };

    const handleDateChange = (val: any) => {
        if (val) {
            webSocketService.sendMessage("taskDueDateChange", {
                tntId: user?.tenant_id,
                taskId: taskData?._id,
                userId: user?._id,
                date: val ? val.format("DD-MM-YYYY HH:mm") : null,
            });
        }
    };

    const handleTypeSubmit = () => {
        webSocketService.sendMessage("taskTypeChange", {
            tntId: user?.tenant_id,
            taskId: taskData?._id,
            type: selectedTypes,
            userId: user?._id,
        });
        setTypeEditVisible(false);
    };

    const handleListClick = (key: string) => {
        setSelectedTypes((prevSelectedTypes) => {
            const updatedSelectedNames = prevSelectedTypes.includes(key) ? prevSelectedTypes.filter((name) => name !== key) : [...prevSelectedTypes, key];

            return updatedSelectedNames;
        });
    };

    const inqryTypeTitle = (
        <Flex justify="space-between" gap={10}>
            <Search placeholder="Search" onChange={handleTypeSearch} />
            <Button type="primary" onClick={handleTypeSubmit}>
                Save
            </Button>
        </Flex>
    );

    const inqryTypeContent = (
        <List>
            {filteredInquiryTypes.map(([key, title]) => (
                <List.Item
                    key={key}
                    style={{
                        backgroundColor: selectedTypes.includes(key) ? "#e0f6fe" : "transparent",
                        padding: "5px 10px",
                        margin: "1px",
                        borderRadius: "6px",
                        cursor: "pointer",
                    }}
                    onClick={() => handleListClick(key)}
                >
                    <Checkbox id={key} checked={selectedTypes.includes(key)} style={{ marginRight: "10px" }}>
                        <label style={{ cursor: "pointer" }}>{title}</label>
                    </Checkbox>
                </List.Item>
            ))}
        </List>
    );

    const drawerCloseHandle = () => {
        setModelShow(false);
        setTimeout(() => {
            dispatch(closeItem(id));
            setSubTaskData([]);
        }, 300);
    };

    const historyTabItem = [
        {
            key: "1",
            label: "Comments",
            children: <CommentsBlock taskData={taskData} />,
        },
        {
            key: "2",
            label: "History",
            children: <HistoryBlock id={id} />,
        },
    ];

    const handlePriority = (e: any) => {
        setIsPrior(e.target.checked);
        webSocketService.sendMessage("taskPriorityUpdate", {
            tntId: user?.tenant_id,
            taskId: taskData._id,
            isPrior: e.target.checked,
            userId: user?._id,
        });
    };

    const handleEditTitleClick = () => {
        setIsEditingTitle(true);
    };

    const addSubTaskList = () => {
        setSubTaskData([
            ...subTaskData,
            {
                name: "SubTask List",
                task: [],
            },
        ]);
    };

    const handleMinimize = () => {
        dispatch(
            addItemInList({
                name: taskData.title,
                _id: taskData._id,
                type: "TASK",
            })
        );
        drawerCloseHandle();
    };

    const handleSubtaskChange = (val: any, index: number) => {
        const taskList = [...subTaskData];
        taskList[index] = val;
        setSubTaskData(taskList);
        setTimeout(() => {
            webSocketService.sendMessage("taskSubtaskUpdate", {
                tntId: user?.tenant_id,
                taskId: taskData?._id,
                userId: user?._id,
                subTask: taskList,
            });
        }, 500);
    };

    const removeSubTaskList = (index: number) => {
        const updatedSubTaskData = subTaskData.filter((_, i) => i !== index);
        setSubTaskData(updatedSubTaskData);
        webSocketService.sendMessage("taskSubtaskDelete", {
            tntId: user?.tenant_id,
            taskId: taskData?._id,
            userId: user?._id,
            index: index,
        });
    };

    const updateAssigneeList = (val: any) => {
        webSocketService.sendMessage("taskAssigneeUpdate", {
            tntId: user?.tenant_id,
            taskId: taskData?._id,
            assignee: val,
            userId: user?._id,
        });
    };
    const uploadButton = (
        <button style={{ border: 0, background: "none" }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    const removeDocHandle = (val: any) => {
        apiService
            .put(`/task/delete-task-doc/${taskData?._id}`, {
                docName: val.name,
            })
            .then((res) => {
                setFileList((prev) => prev.filter((fl) => fl.name !== val.name));
            })
            .catch((e) => console.log(e));
    };

    const handlePreview = async (file: UploadFile) => {
        let fileType = ["jpeg", "png", "jpg"];
        if (fileType.includes(file.name.split(".")[1])) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as FileType);
            }

            setPreviewImage(file.url || (file.preview as string));
            setPreviewOpen(true);
        } else {
            window.open(file.url, "_blank")?.focus();
        }
    };
    const fileBeforUploadHandle = async (file: any) => {
        return false;
    };
    const fileDownloadHandle = async (fileName: string, type: string) => {
        downloadDocument(`task_doc/${user?.tenant_id}/${taskData?._id}/${fileName}`, type);
    };
    const renderDrawerWidth = () => {
        switch (layer) {
            case 0:
                return "calc(100% - 233px)";
            case 1:
                return "calc(97% - 233px)";
            case 2:
                return "calc(94% - 233px)";
            case 3:
                return "calc(91% - 233px)";
            default:
                return "calc(91% - 233px)";
        }
    };
    const colsebarPosition = () => {
        switch (layer) {
            case 0:
                return openItemList.length * 18;
            default:
                return (openItemList.length - (layer || 0)) * 18;
        }
    };

    return (
        <>
            <Drawer
                onClose={drawerCloseHandle}
                open={modelShow}
                width={renderDrawerWidth()}
                className={TaskStyleCss.taskDrawer}
                classNames={{
                    mask: currentOpen._id === id ? "show-mask" : "hide-mask",
                }}
                styles={{
                    header: {
                        display: "none",
                    },
                }}
                style={{ boxShadow: "0px 0px 5px white" }}
            >
                <div className="close-bar" style={{ top: colsebarPosition() }}>
                    <Flex onClick={drawerCloseHandle} className="close-label" align="center">
                        {currentOpen._id === id && <CloseOutlined style={{ fontSize: "12px" }} />}
                        <span style={{ lineHeight: "22px" }}>TASK</span>
                    </Flex>
                    {currentOpen._id === id && (
                        <>
                            <span className="minimize-label" onClick={handleMinimize}>
                                {minimizeArrow}
                            </span>
                            <div className={`sync-label ${saved ? "saved" : ""}`}>
                                {syncing ? (
                                    <Flex align="center">
                                        <SyncOutlined spin className="mr-2" style={{ color: "#525C69" }} />
                                        Saving...
                                    </Flex>
                                ) : saved ? (
                                    <Flex>
                                        <CheckOutlined style={{ color: "white" }} className="mr-2" />
                                        <span style={{ color: "white" }}>Saved</span>
                                    </Flex>
                                ) : (
                                    <CheckOutlined style={{ color: "#525C69" }} />
                                )}
                            </div>
                        </>
                    )}
                </div>

                <Form name="task" layout="vertical" form={taskForm}>
                    {loading ? (
                        <Skeleton active />
                    ) : (
                        <div className={TaskStyleCss.taskDetailDrawer}>
                            <div className={`drawer-header`}>
                                <Flex align="center" justify="space-between">
                                    <Flex align={"center"}>
                                        <p>
                                            {taskData?.task_type.map((inquiry: string, index: number) => {
                                                const inquiryData = INQUIRY_TYPE[inquiry];
                                                const colors = ["orange", "purple", "green", "geekblue"];

                                                return (
                                                    <Tag color={colors[index % colors?.length]} key={index}>
                                                        {inquiryData?.title}
                                                    </Tag>
                                                );
                                            })}
                                        </p>
                                        <Popover
                                            overlayClassName="assignTo"
                                            content={inqryTypeContent}
                                            title={inqryTypeTitle}
                                            open={typeEditVisible}
                                            onOpenChange={handleTypeVisibleChange}
                                            trigger="click"
                                        >
                                            <button
                                                style={{
                                                    backgroundColor: "transparent",
                                                    border: "none",
                                                }}
                                            >
                                                <EditOutlined style={{ color: "#535c6957" }} />
                                            </button>
                                        </Popover>
                                    </Flex>
                                </Flex>
                            </div>
                            <Flex gap={20}>
                                <div style={{ width: "100%" }}>
                                    <Card style={{ marginBottom: 20 }}>
                                        <Row className="title-wrapper" justify={"space-between"} align={"top"}>
                                            <Col xxl={20} xl={18} lg={16} sm={14} xs={12}>
                                                <Form.Item name="title" className="mb-0">
                                                    {isEditingTitle ? (
                                                        <Input
                                                            placeholder="Enter task name"
                                                            variant="borderless"
                                                            onBlur={(e: any) => handleTitleBlur(e)}
                                                            defaultValue={taskData?.title}
                                                            autoFocus
                                                        />
                                                    ) : (
                                                        <div style={{ cursor: "pointer" }}>
                                                            <Typography.Text>{taskData?.title || "Enter task name"}</Typography.Text>
                                                            <button
                                                                onClick={handleEditTitleClick}
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    border: "none",
                                                                }}
                                                            >
                                                                <EditOutlined style={{ paddingLeft: "10px", color: "#535c6957" }} />
                                                            </button>
                                                        </div>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item className="mb-0" name={"is_prior"} valuePropName="checked">
                                                    <Checkbox onChange={(e) => handlePriority(e)}>
                                                        <Flex gap={8} align="center">
                                                            High Priority <img src={isPrior ? fireActiveIcon : fireIcon} alt="priority icon" />
                                                        </Flex>
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item className={TaskStyleCss.notes}>
                                            <InputBox
                                                type="textarea"
                                                name={"notes"}
                                                onBlur={noteUpdate}
                                                onChange={handleNoteChange}
                                                placeholder="Add Notes here"
                                                style={{ marginBottom: "10px" }}
                                                autoSize={{ minRows: "8", maxRows: "22" }}
                                            />
                                        </Form.Item>

                                        <Divider className="divider" />
                                        <div className={TaskStyleCss.imageView}>
                                            <Upload
                                                listType="picture-card"
                                                fileList={fileList}
                                                beforeUpload={fileBeforUploadHandle}
                                                onChange={handleChange}
                                                onRemove={removeDocHandle}
                                                onPreview={handlePreview}
                                                itemRender={(ele, file: any) => {
                                                    let ext = file.name.split(".").pop();
                                                    let imgExt = ["jpeg", "jpg", "png"];
                                                    return (
                                                        <FileListItem
                                                            file={file}
                                                            imgExt={imgExt}
                                                            ext={ext}
                                                            removeDocHandle={removeDocHandle}
                                                            fileDownloadHandle={fileDownloadHandle}
                                                        />
                                                    );
                                                }}
                                            >
                                                {uploadButton}
                                            </Upload>
                                        </div>
                                        <Divider className="divider" />
                                        <div className={TaskStyleCss.checkList}>
                                            {subTaskData?.map((st: subTaskDataListInterface, index: number) => (
                                                <CheckList
                                                    data={st}
                                                    taskListChange={(val: any) => handleSubtaskChange(val, index)}
                                                    removeSubTaskList={() => removeSubTaskList(index)}
                                                />
                                            ))}
                                            <Button
                                                style={{ color: "#80868e", fontSize: "14px", paddingLeft: 0 }}
                                                size="large"
                                                type="link"
                                                onClick={addSubTaskList}
                                                icon={<PlusOutlined style={{ fontSize: "11px", lineHeight: "1", fill: "#80868e" }} />}
                                            >
                                                Add SubTask List
                                            </Button>
                                        </div>
                                    </Card>
                                    <Tabs className={TaskStyleCss.taskActivityTab} defaultActiveKey="1" type="card" size={"middle"} items={historyTabItem} />
                                </div>
                                <div className={TaskStyleCss.taskDetailSideBar}>
                                    <Card>
                                        <div className="detailStatus">
                                            <Typography
                                                style={{
                                                    color: "white",
                                                }}
                                            >
                                                {daysRemaining === null
                                                    ? "No date available"
                                                    : daysRemaining > 0
                                                    ? `${daysRemaining} ${daysRemaining === 1 ? "day" : "days"} Remaining`
                                                    : `Overdue ${Math.abs(daysRemaining)} ${Math.abs(daysRemaining) === 1 ? "day" : "days"}`}
                                            </Typography>
                                        </div>
                                        <div style={{ padding: "20px" }}>
                                            <div className="details taskDetails">
                                                <Row>
                                                    <Col span={9}>
                                                        <Typography.Text className="title" style={{ width: "70px" }}>
                                                            Due Date:
                                                        </Typography.Text>
                                                    </Col>
                                                    <Col span={15}>
                                                        {taskData?.status === "INPROGRESS" || taskData?.status === "PENDING" ? (
                                                            <DatePicker
                                                                showTime={true}
                                                                placeholder="DD-MM-YYYY HH:mm"
                                                                format={{
                                                                    format: "DD-MM-YYYY HH:mm",
                                                                    type: "mask",
                                                                }}
                                                                variant="borderless"
                                                                className="label-title"
                                                                size="large"
                                                                style={{
                                                                    padding: 0,
                                                                }}
                                                                value={dayjs(taskData?.due_date || null, "DD-MM-YYYY HH:mm")}
                                                                onChange={handleDateChange}
                                                                required
                                                                name={"dueDate"}
                                                            />
                                                        ) : (
                                                            <Text className="data">{taskData?.due_date ? dayjs(taskData.due_date).format("DD-MM-YYYY") : ""}</Text>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Divider style={{ margin: "5px 0" }} />
                                            <div className="details">
                                                <Row>
                                                    <Col span={9}>
                                                        <Typography.Text className="title" style={{ width: "70px" }}>
                                                            Created On:{" "}
                                                        </Typography.Text>
                                                    </Col>
                                                    <Col span={15}>
                                                        <Text className="data">{taskData?.created_on ? dayjs(taskData.created_on).format("DD-MM-YYYY HH:mm") : ""}</Text>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Divider style={{ margin: "5px 0" }} />
                                            <div className="details status">
                                                <Select
                                                    style={{ width: "100%" }}
                                                    suffixIcon={<CaretDownOutlined style={{ color: "#525c69" }} />}
                                                    onChange={(e) => handleStatusChange(e)}
                                                    value={taskData?.task_status}
                                                    options={Object.keys(INQUIRY_TYPE_STATUS)?.map((key) => ({
                                                        value: key,
                                                        label: INQUIRY_TYPE_STATUS[key]?.title?.toUpperCase(),
                                                    }))}
                                                />
                                            </div>
                                            <div className="details">
                                                <Typography.Text className="title">Created By:</Typography.Text>
                                                <Divider style={{ margin: "5px 0" }} />
                                                <Avatar size={25} icon={<UserOutlined />} style={{ marginRight: 8, background: "rgb(83, 92, 106)" }} />
                                                <Text className="data">{taskData?.created_by?.full_name}</Text>
                                            </div>
                                            <div className="details taskDetails">
                                                <Flex justify="space-between" align="center">
                                                    <Typography.Text className="title">Assignee</Typography.Text>
                                                    <UserInvite onSubmit={updateAssigneeList} assignList={assignList} assigned={taskData?.assign_to} />
                                                </Flex>
                                                <Divider style={{ margin: "5px 0" }} />
                                                <>
                                                    {Array.isArray(taskData?.assign_to) &&
                                                        taskData.assign_to.slice(0, showAll ? taskData.assign_to.length : 4).map((assignee: any, index: any) => (
                                                            <div style={{ paddingBottom: "5px" }} key={index}>
                                                                <Avatar
                                                                    size={25}
                                                                    style={{
                                                                        marginRight: 7,
                                                                        backgroundColor: colors[index % colors.length],
                                                                        fontSize: "12px",
                                                                        padding: "1px",
                                                                        color: "#ffffffd6",
                                                                        fontWeight: "500",
                                                                        lineHeight: "1",
                                                                    }}
                                                                >
                                                                    {getInitials(assignee?.full_name)}
                                                                </Avatar>
                                                                <Text className="data">{assignee?.full_name}</Text>
                                                            </div>
                                                        ))}
                                                    {Array.isArray(taskData?.assign_to) && taskData.assign_to.length > 4 && (
                                                        <Button type="link" onClick={handleShowMore} className="showMore">
                                                            {showAll ? "Show Less" : "Show More..."}
                                                        </Button>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </Flex>
                        </div>
                    )}
                </Form>
            </Drawer>
            {previewImage && (
                <Image
                    wrapperStyle={{ display: "none" }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                />
            )}
        </>
    );
}

export default TaskDetails;
