import { Button, Flex, FormInstance, Typography } from "antd";
import React from "react";
import { InputBox } from "../../../components/FormInput";
import dayjs from "dayjs";
interface IDueDate {
    form: FormInstance<any>;
}
function DueDate({form}:IDueDate) {
    const setTomorrowDate = () => {
        const tomorrow = dayjs().add(1, "day");
        form.setFieldValue("dueDate", tomorrow);
    };

    const setTodayDate = () => {
        const today = dayjs();
        form.setFieldValue("dueDate", today);
    };
    return (
        <div className="" style={{ paddingBottom: "10px" }}>
            <Flex align="center" gap={30}>
                <Typography.Text className="label">Due Date</Typography.Text>
                <InputBox name={"dueDate"} showTime={true} type="date" style={{ width: "220px" }} />
                <Flex align="center" gap={10}>
                    <Button
                        onClick={setTodayDate}
                        style={{
                            borderBottom: "1px dashed #5e6675",
                            borderRadius: "0px",
                            padding: "0px",
                            color: "#5e6675",
                        }}
                    >
                        Today
                    </Button>
                    <Button
                        onClick={setTomorrowDate}
                        style={{
                            borderBottom: "1px dashed #5e6675",
                            borderRadius: "0px",
                            padding: "0px",
                            color: "#5e6675",
                        }}
                    >
                        Tomorrow
                    </Button>
                </Flex>
            </Flex>
        </div>
    );
}

export default DueDate;
