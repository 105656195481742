import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LocalStorageService } from "./utils/LocalStorage";
import { AppDispatch } from "./store";
import { useDispatch } from "react-redux";
import { isLogin, setProfileData, setUserData, UserInterface } from "./slices/userSlice";
import AxiosService from "./utils/APIService";
import webSocketService from "./utils/websocketService";
import AppRoutes from "./AppRoutes";

const App = () => {
    const dispatch = useDispatch<AppDispatch>();
    const apiService = new AxiosService();
    const navigate = useNavigate();

    const token = LocalStorageService.getItem("token");
    const userData: UserInterface = LocalStorageService.getItem("user");

    useEffect(() => {
        if (!webSocketService.isConnected() && userData?._id) {
            webSocketService.connect(process.env.REACT_APP_SOCKET_URL as string);
            webSocketService.sendMessage('register', userData._id);
        }

        if (token) {
            getLoggedinUserData();
        } else {
            clearUserData();
        }

        return () => webSocketService.disconnect();
    }, [token, userData]);

    const getLoggedinUserData = () => {
        apiService
            .get("/auth/me")
            .then((res: any) => {
                dispatch(isLogin(true));
                dispatch(setProfileData(res.profile));
                dispatch(setUserData(res.user));
            })
            .catch(() => handleLogout());
    };

    const clearUserData = () => {
        dispatch(isLogin(false));
        dispatch(setProfileData(null));
        dispatch(setUserData(null));
    };

    const handleLogout = () => {
        LocalStorageService.removeItem("token");
        LocalStorageService.removeItem("user");
        clearUserData();
        navigate("/login");
    };  
    return <AppRoutes />;
};

export default App;
