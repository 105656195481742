import { useEffect, useState } from "react";
import { Avatar, Button, Dropdown, Flex, List, MenuProps, Space, Tooltip } from "antd";
import { MenuOutlined, SettingOutlined } from "@ant-design/icons";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import AxiosService from "../../utils/APIService";
import webSocketService from "../../utils/websocketService";
import { formateDate, getInitials, statusClassName } from "../../utils/commonFunctions";
import { INQUIRY_TYPE, INQUIRY_TYPE_STATUS, TASK_STATUS } from "../../utils/const";
import Icon from "./Icon";
import fireActiveIcon from "../../assets/fireactive.svg";
import Taskcss from "./style.module.scss";

const colors = ["#76a3da"];

interface PendingTaskInterface {
    filter: any;
    CompletedList: any[];
}

function CompetedList({ filter, CompletedList }: PendingTaskInterface) {
    const { user } = useSelector((state: RootState) => state.user);
    const apiService = new AxiosService();
    const [list, setList] = useState<any[]>([]);

    useEffect(() => {
        webSocketService.onMessage("watchListRefresh", (val) => {
            if (val.tenantId === user?.tenant_id) {
                fetchWishlist();
            }
        });
    }, [user]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            fetchWishlist();
        }, 100);
        return () => clearTimeout(timeOut);
    }, [filter]);

    const fetchWishlist = () => {
        apiService
            .get("/task/list", {
                status: TASK_STATUS.complete,
                ...filter,
            })
            .then((res: any) => {
                setList(res.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const items: MenuProps["items"] = [
        {
            key: TASK_STATUS.pending,
            label: "Move back to Task",
        },
        {
            key: TASK_STATUS.completedbyUser,
            label: "Move back to Watchlist",
        },
    ];

    const handleTaskMove = (status: any, id: string) => {
        apiService
            .put(`/task/change-status/${id}`, {
                status: status.key,
            })
            .then((res) => {
                console.log(res);
            })
            .catch((e) => console.log(e));
    };

    return (
        <>
            <div className={Taskcss.taskCollaps}>
                <div className="task-header">
                    <span className="length-count">{String(list?.length)?.padStart(2, "0")}</span>
                    <span>Completed</span>
                </div>
                <List size="small" bordered>
                    <List.Item>
                        <div className="task-grid-wrapper header" style={{ gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)" }}>
                            <Space>
                                <SettingOutlined style={{ lineHeight: "1", marginLeft: "35px", color: "#6f7994a3", marginRight: "1px" }} />
                                <span>NAME</span>
                            </Space>
                            <div>
                                <span>DUE DATE</span>
                            </div>
                            <div className="assigneebg">
                                <span>ASSIGNEE</span>
                            </div>
                        </div>
                    </List.Item>
                    {CompletedList?.map((dt) => {
                        return (
                            <List.Item key={dt._id}>
                                <div className="task-grid-wrapper" style={{ gridTemplateColumns: "1fr  minmax(160px, 160px) minmax(169px, 160px)" }}>
                                    <Flex gap={10} align="center" justify="space-between">
                                        <Space>
                                            {dt.task.task_type?.length > 1 ? (
                                                <Tooltip title="Multiple Inquiries">
                                                    <Icon name="MULTI" style={{ cursor: "pointer" }} />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={INQUIRY_TYPE[dt?.task?.task_type]?.title}>
                                                    <Icon name={dt?.task?.task_type?.[0]} style={{ cursor: "pointer" }} />
                                                </Tooltip>
                                            )}
                                            <div onClick={(event) => event.stopPropagation()}>
                                                <Dropdown menu={{ items, onClick: (val: any) => handleTaskMove(val, dt.task._id) }} trigger={["click"]}>
                                                    <Button type="link" style={{ padding: "0px", height: "auto" }}>
                                                        <MenuOutlined style={{ lineHeight: "1" }} />
                                                    </Button>
                                                </Dropdown>
                                            </div>
                                            <span className="list-item-title">
                                                {dt?.task?.title} {dt?.task?.is_high_prior && <img src={fireActiveIcon} />}
                                            </span>
                                        </Space>
                                        <div>
                                            <div style={{ pointerEvents: "none", cursor: "not-allowed" }} className="deleteStatus">
                                                <span className={` ${statusClassName(dt.task.task_status)}`}>
                                                    {INQUIRY_TYPE_STATUS[dt?.task?.task_status]?.title?.toUpperCase()}
                                                </span>
                                            </div>
                                        </div>
                                    </Flex>
                                    <div>
                                        <span>{formateDate(dt.task.due_date)}</span>
                                    </div>
                                    <div className="assigneebg">
                                        <Flex align="center" className="assignee-cell" style={{ width: "100%" }}>
                                            <Avatar.Group
                                                max={{
                                                    count: 3,
                                                    style: { backgroundColor: "#76a3da" },
                                                    popover: { rootClassName: "AsssignnePopover" },
                                                }}
                                                size={28}
                                            >
                                                {dt.task.assign_to.map((asn: any, idx: any) => {
                                                    return (
                                                        <Tooltip title={asn.full_name} key={idx}>
                                                            <Avatar
                                                                style={{
                                                                    backgroundColor: colors[idx % colors.length],
                                                                    marginLeft: "-6px",
                                                                }}
                                                            >
                                                                {getInitials(asn.full_name)}
                                                            </Avatar>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </Avatar.Group>
                                        </Flex>
                                    </div>
                                </div>
                            </List.Item>
                        );
                    })}
                </List>
            </div>
        </>
    );
}

export default CompetedList;
