import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { IoDocumentTextOutline } from "react-icons/io5";
import { CgSoftwareDownload } from "react-icons/cg";
import { useMemo } from "react";
const _ = require("lodash")
interface FileListItemProps {
    file: any;
    imgExt: string[];
    ext: string;
    removeDocHandle: (file: any) => void;
    fileDownloadHandle?: (fileName: string, ext: string) => void;
}

function FileListItem({ file, imgExt, ext, removeDocHandle, fileDownloadHandle }: FileListItemProps) {
    const fileURL = useMemo(() => {
        return file?.url || (file?.originFileObj ? URL.createObjectURL(file.originFileObj) : '');
    }, [file]);

    const isImage = imgExt.includes(ext);

    const handleRemoveClick = () => removeDocHandle(file);
    const handleDownloadClick = () => {
        if(_.has(file, "originFileObj")){
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }else{
            fileDownloadHandle && fileDownloadHandle(file.name, ext)
        }
    };

    return (
        <div className="custom-file-list-item">
            <div className="custom-file-list-block">
                {isImage ? (
                    <img src={fileURL || file.preview} alt={file.name || "File"} />
                ) : (
                    <div className="doc-block">
                        <IoDocumentTextOutline size={50} />
                    </div>
                )}
                <div className="overlay">
                    <a href={fileURL} target="_blank" rel="noopener noreferrer">
                        <EyeOutlined />
                    </a>
                    <div onClick={handleRemoveClick}>
                        <DeleteOutlined />
                    </div>
                </div>
            </div>
            <div className="download-block" onClick={handleDownloadClick}>
                <CgSoftwareDownload />
            </div>
        </div>
    );
}

export default FileListItem;
