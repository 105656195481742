import React from "react";
import { Modal, Button, Typography, Flex } from "antd";
import styleCss from "./style.module.scss";

interface ConfirmationModalProps {
    isVisible: boolean;
    onClose: () => void;
    onConfirm: () => void;
    message: string;
    confirmText?: string;
    cancelText?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isVisible, onClose, onConfirm, message, confirmText = "Delete", cancelText = "Cancel" }) => {
    return (
        <Modal centered open={isVisible} onCancel={onClose} width={430} footer={null} closable={false} className={styleCss.confirmDelete}>
            <Typography>{message}</Typography>
            <Flex justify="end" style={{ marginTop: "16px" }}>
                <Button onClick={onClose} className="cancelBtn">
                    {cancelText}
                </Button>
                <Button onClick={onConfirm} className="deleteBtn">
                    {confirmText}
                </Button>
            </Flex>
        </Modal>
    );
};

export default ConfirmationModal;
