import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserInterface {
    _id: string;
    full_name: string;
    email: string;
    contact_no: string;
    role: "ADMIN" | "AGENT_USER" | "AGENT_ADMIN";
    tenant_id: string;
}

export interface DepartmentInterface {
    name: string;
    _id: string;
}

export interface UserProfileInterface {
    departments: DepartmentInterface[];
}

export interface IUserState {
    isUserLogin: boolean;
    user?: UserInterface | null;
    profile?: UserProfileInterface | null;
}

const initialState: IUserState = {
    isUserLogin: false,
    user: null,
    profile: null
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        isLogin(state, action: PayloadAction<boolean>) {
            state.isUserLogin = action.payload;
        },
        setUserData(state, action: PayloadAction<UserInterface | null>) {
            state.user = action.payload;
        },
        setProfileData(state, action: PayloadAction<UserProfileInterface | null>) {
            state.profile = action.payload;
        },
    },
});

export const { isLogin, setUserData, setProfileData } = userSlice.actions;

export default userSlice.reducer;
