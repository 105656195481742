import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Button, Mentions, Popover } from "antd";
import { MentionsOptionProps } from "antd/es/mentions";
import dayjs from "dayjs";
import _ from "lodash";
import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { AppDispatch, RootState } from "../../store";
import { UserOutlined } from "@ant-design/icons";
import webSocketService from "../../utils/websocketService";
import { fetchComments } from "../../slices/taskSlice";

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(advancedFormat);

const emojiData = [
    { label: "Like", emoji: "👍" },
    { label: "Love", emoji: "😍" },
    { label: "Haha", emoji: "😂" },
    { label: "Wow", emoji: "😮" },
    { label: "Sad", emoji: "😢" },
    { label: "Angry", emoji: "😠" },
    { label: "Facepalm", emoji: "🤦" },
];

const Comment = ({ comment, taskData, onReply, onEdit, isEditing, setEditingCommentId }: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const { user } = useSelector((state: RootState) => state.user);
    const [editedText, setEditedText] = useState(comment.message);
    const [mentions, setMentions] = useState<{ _id: string; full_name: string }[]>(comment?.mentions || []);

    const getFormattedDate = (createdOn: string) => {
        if (!createdOn) return;
        const now = dayjs();
        const inputDate = dayjs(createdOn);

        if (inputDate.isSame(now, "day")) {
            return inputDate.format("hh:mm A");
        } else if (inputDate.isSame(now.subtract(1, "day"), "day")) {
            return `Yesterday at ${inputDate.format("hh:mm A")}`;
        } else {
            return inputDate.format("MMM D, hh:mm A");
        }
    };

    const highlightMentions = (text: string, mentionsIdList: any[]) => {
        const mentionsList = taskData?.assign_to?.filter((mention: any) => mentionsIdList?.includes(mention?._id));
        const mentionRegex = new RegExp(`@(${mentionsList?.map((m: any) => _.escapeRegExp(m.full_name))?.join("|")})`, "g");

        const parts = text?.split(mentionRegex);

        return parts?.map((part, index) => {
            const isMention = mentionsList?.some((m: any) => part === m?.full_name);
            if (isMention) {
                return (
                    <span key={index} className="mentionHighlight">
                        {`@${part}`}
                    </span>
                );
            }
            return part;
        });
    };

    const emojiPopoverContent = (
        <div style={{ display: "flex", gap: "10px", padding: "10px" }}>
            {emojiData?.map((emoji) => (
                <span key={emoji?.label} style={{ cursor: "pointer", fontSize: "24px", lineHeight: "1" }} onClick={() => console.log(emoji.label)}>
                    {emoji?.emoji}
                </span>
            ))}
        </div>
    );

    const handleEdit = () => {
        if (!!comment.mentions) {
            const commentMention = taskData?.assign_to?.filter((mention: any) => comment?.mentions?.includes(mention?._id));
            setMentions(commentMention);
        }
        setEditingCommentId(comment?._id);
        setEditedText(comment?.message);
    };

    const handleSendEdit = () => {
        onEdit(comment._id, editedText, mentions);
        setEditingCommentId(null);
        setEditedText("");
        setMentions([]);
    };

    const handleCancelEdit = () => {
        setEditingCommentId(null);
        setEditedText("");
        setMentions([]);
    };

    const onTextChange = (text: string) => {
        setEditedText(text);
        const filteredMentions = mentions?.filter((mention) => text?.includes(mention?.full_name));
        const uniqueMentions = _.uniqBy(filteredMentions, "_id");
        setMentions(uniqueMentions);
    };

    const onSelect = (option: MentionsOptionProps) => {
        const selectedUser = taskData?.assign_to?.find((user: any) => user?._id === option?.key);
        if (selectedUser) {
            setMentions((prev) => [...prev, { _id: selectedUser._id, full_name: selectedUser.full_name }]);
        }
    };

    const handleDelete = (id: string) => {
        webSocketService.sendMessage("task/comment/delete", {
            tntId: user?.tenant_id,
            commentId: id,
            userId: user?._id,
        });

        dispatch(fetchComments(taskData?._id));
    };

    return (
        <>
            <div className="comment-list" key={comment?._id}>
                <div className="avatar">
                    <Avatar size={36} icon={<UserOutlined />} style={{ marginRight: 8 }} />
                </div>
                <div>
                    <div className="comment-text-section">
                        <div style={{ display: "flex", gap: 6, alignItems: "center" }}>
                            <span className="user-name">{comment?.user?.full_name} </span>
                            <span className="comment-time">{getFormattedDate(comment?.created_on)}</span>
                        </div>
                        <span>{highlightMentions(comment?.message, comment?.mentions || [])}</span>
                    </div>

                    <div className="comment-reply" style={{ marginTop: 8 }}>
                        <Popover content={emojiPopoverContent} trigger="hover" placement="topLeft">
                            <span onClick={() => console.log("click in like ")}>Like</span>
                        </Popover>
                        <span onClick={() => onReply(comment.user)}>Reply</span>
                        {user?._id === comment.user?._id && (
                            <>
                                <span onClick={handleEdit}>Edit</span>
                                <span onClick={() => handleDelete(comment?._id)}>Delete</span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {isEditing && (
                <div style={{ margin: "8px 0px", width: "75%", paddingLeft: 60 }}>
                    <Mentions
                        placeholder="Input @ to mention people"
                        rows={4}
                        value={editedText}
                        onChange={onTextChange}
                        onSelect={onSelect}
                        options={taskData?.assign_to?.map((value: any) => ({
                            key: value?._id,
                            value: value.full_name,
                            label: value.full_name,
                        }))}
                    />
                    <Button type="primary" onClick={handleSendEdit} style={{ marginTop: 8 }}>
                        Send
                    </Button>
                    <Button onClick={handleCancelEdit} style={{ marginTop: 8, marginLeft: 8 }}>
                        Cancel
                    </Button>
                </div>
            )}
        </>
    );
};

export default Comment;
