import { Col, Flex, Form, Row, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import TaskStyleCss from "./style.module.scss";
import { DRAWER_TYPE, INQUIRY_TYPE } from "../../../utils/const";
import Icon from "../Icon";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
const { Text } = Typography;
function DrawerHeader() {
    const [isTaskTypeOpen, setIsTaskTypeOpen] = useState(false);
    const { taskDrawerOpen } = useSelector((state: RootState) => state.layout);
    useEffect(() => {
        if (taskDrawerOpen) {
            setIsTaskTypeOpen(true);
        }else {
            setIsTaskTypeOpen(false);
        }
    }, [taskDrawerOpen]);
    return (
        <Row className={`drawer-header ${TaskStyleCss.drawerSelect}`} justify={"space-between"}>
            <Col>
                <Space>
                    <Text className="drawer-title">New Task</Text>
                    <Form.Item name="type" className="mb-0" required={true}>
                        <Select
                            placeholder="TASK TYPE"
                            style={{ width: 270 }}
                            options={Object.keys(INQUIRY_TYPE).map((ky) => ({
                                label: (
                                    <Flex align="center">
                                        <Icon name={ky} />
                                        <span className="pl-2">{INQUIRY_TYPE[ky].title}</span>
                                    </Flex>
                                ),
                                value: ky,
                            }))}
                            className="template-dropdown"
                            mode="multiple"
                            rootClassName="taskType"
                            open={isTaskTypeOpen}
                            onDropdownVisibleChange={(open) => setIsTaskTypeOpen(open)}
                        />
                    </Form.Item>
                </Space>
            </Col>
            <Col>
                <Select
                    className="template-dropdown"
                    placeholder="TASK TEMPLATES"
                    style={{ width: 153 }}
                    options={[
                        { value: "jack", label: "Jack" },
                        { value: "lucy", label: "Lucy" },
                        { value: "Yiminghe", label: "yiminghe" },
                    ]}
                />
            </Col>
        </Row>
    );
}

export default DrawerHeader;
