import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LayoutState {
    isLoading: boolean;
}

const initialState: LayoutState = {
    isLoading: false,
};

const apiServiceSlice = createSlice({
    name: "apiService",
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
    },
});

export const { setIsLoading } = apiServiceSlice.actions;

export default apiServiceSlice.reducer;
