// Bin Note Lists

import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Masonry } from "@mui/lab";
import { AppDispatch, RootState } from "../../store";
import { getNoteIds } from "../../utils/commonFunctions";
import { bulkDelete, removeBulkBinNoteList } from "../../slices/notesSlice";
import NoteCard from "./NoteCard";
import styleCss from "./style.module.scss";
import ConfirmationModal from "./ConfirmationModel";
import { useState } from "react";

const BinNoteList = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { binNoteList } = useSelector((state: RootState) => state.notes);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

    const handleEmptyBtnClick = () => {
        dispatch(removeBulkBinNoteList());
        setDeleteModalVisible(false);
        dispatch(bulkDelete(getNoteIds(binNoteList)));
    };

    return (
        <div className={styleCss.noteGirdViewContainer}>
            {!!binNoteList.length && (
                <>
                    <div className="bin-notification">
                        <Typography>Notes in Trash are deleted after 7 days.</Typography>
                        <Button onClick={() => setDeleteModalVisible(true)} style={{color:'#4096ff'}}>Empty Bin</Button>
                    </div>
                    <div className={styleCss.filterTitle}>
                        <Masonry className={styleCss.myMasonryGrid}>
                            {binNoteList?.map((note: any) => (
                                <NoteCard {...note} key={note?.id} />
                            ))}
                        </Masonry>
                    </div>
                </>
            )}
            {!binNoteList.length && (
                <div className="no-data-notification">
                    <RiDeleteBin6Line size={90} className="note-no-data-icon" />
                    <Typography className="no-data-text">No notes in Bin</Typography>
                </div>
            )}
            <ConfirmationModal
                isVisible={isDeleteModalVisible}
                onClose={() => setDeleteModalVisible(false)}
                onConfirm={handleEmptyBtnClick}
                message="Empty trash? All notes in Trash will be permanently deleted."
                confirmText="Empty Trash"
            />
        </div>
    );
};

export default BinNoteList;
