import { useEffect } from "react";
import { Avatar, Button, Dropdown, Flex, List, MenuProps, Select, Space, Tooltip } from "antd";
import { MenuOutlined, SettingOutlined } from "@ant-design/icons";
import { AppDispatch, RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import AxiosService from "../../utils/APIService";
import webSocketService from "../../utils/websocketService";
import { INQUIRY_TYPE, INQUIRY_TYPE_STATUS, TASK_STATUS } from "../../utils/const";
import { formateDate, getInitials, statusClassName } from "../../utils/commonFunctions";
import fireActiveIcon from "../../assets/fireactive.svg";
import Icon from "./Icon";
import { useInquiry } from ".";
import { UserInvite } from "../../components/UserInviteModel";
import { openTask } from "../../slices/taskSlice";
import Taskcss from "./style.module.scss";

const colors = ["#76a3da"];
interface PendingTaskInterface {
    filter: any;
    WatchList: any[];
}

function WatchList({ filter, WatchList }: PendingTaskInterface) {
    const { user } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();
    const { assignList }: any = useInquiry();
    const apiService = new AxiosService();

    useEffect(() => {
        webSocketService.onMessage("watchListRefresh", (val) => {
            if (val.tenantId === user?.tenant_id) {
            }
        });
    }, [user]);

    useEffect(() => {
        const timeOut = setTimeout(() => {}, 100);
        return () => clearTimeout(timeOut);
    }, [filter]);

    const items: MenuProps["items"] = [
        {
            key: TASK_STATUS.pending,
            label: "Move back to Task",
        },
        {
            key: TASK_STATUS.complete,
            label: "Move to Completed",
        },
    ];

    const handleTaskMove = (status: any, id: string) => {
        apiService
            .put(`/task/change-status/${id}`, {
                status: status.key,
            })
            .then((res) => {
                console.log(res);
            })
            .catch((e) => console.log(e));
    };

    const handleTaskClick = (item: any) => {
        dispatch(openTask(item.task._id));
        if (!item.read_status) {
            webSocketService.sendMessage("taskRead", {
                tntId: user?.tenant_id,
                taskId: item.task._id,
                userId: user?._id,
            });
        }
    };

    const handleChange = (val: string, id: string) => {
        webSocketService.sendMessage("taskStatusUpdate", {
            tntId: user?.tenant_id,
            taskId: id,
            userId: user?._id,
            status: val,
        });
    };

    const updateAssigneeList = (val: any, id: string) => {
        webSocketService.sendMessage("taskAssigneeUpdate", {
            tntId: user?.tenant_id,
            taskId: id,
            assignee: val,
            userId: user?._id,
        });
    };

    return (
        <>
            <div className={Taskcss.taskCollaps}>
                <div className="task-header">
                    <span className="length-count">{String(WatchList?.length)?.padStart(2, "0")}</span>
                    <span>Watchlist</span>
                </div>
                <List size="small" bordered>
                    <List.Item>
                        <div className="task-grid-wrapper header" style={{ gridTemplateColumns: "1fr  minmax(160px, 160px) minmax(169px, 160px)" }}>
                            <Space>
                                <SettingOutlined style={{ lineHeight: "1", marginLeft: "35px", color: "#6f7994a3", marginRight: "1px" }} />
                                <span>NAME</span>
                            </Space>
                            <div>
                                <span>DUE DATE</span>
                            </div>
                            <div className="assigneebg">
                                <span>ASSIGNEE</span>
                            </div>
                        </div>
                    </List.Item>
                    {WatchList?.map((dt: any) => {
                        return (
                            <List.Item key={dt._id}>
                                <div className="task-grid-wrapper" style={{ gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)" }}>
                                    <Flex gap={10} align="center" justify="space-between">
                                        <Space>
                                            {dt.task.task_type?.length > 1 ? (
                                                <Tooltip title="Multiple Inquiries">
                                                    <Icon name="MULTI" style={{ cursor: "pointer" }} />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={INQUIRY_TYPE[dt?.task?.task_type]?.title}>
                                                    <Icon name={dt?.task?.task_type?.[0]} style={{ cursor: "pointer" }} />
                                                </Tooltip>
                                            )}
                                            <div onClick={(event) => event.stopPropagation()}>
                                                <Dropdown menu={{ items, onClick: (val: any) => handleTaskMove(val, dt.task._id) }} trigger={["click"]}>
                                                    <Button type="link" style={{ padding: "0px", height: "auto" }}>
                                                        <MenuOutlined style={{ lineHeight: "1" }} />
                                                    </Button>
                                                </Dropdown>
                                            </div>
                                            <span
                                                className="list-item-title name"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleTaskClick(dt);
                                                }}
                                            >
                                                {dt?.task?.title} {dt?.task?.is_high_prior && <img src={fireActiveIcon} />}
                                            </span>
                                        </Space>
                                        <div className="status">
                                            <Select
                                                style={{ width: "100%" }}
                                                size="small"
                                                onChange={(e) => handleChange(e, dt.task._id)}
                                                value={dt.task.task_status}
                                                options={Object.keys(INQUIRY_TYPE_STATUS).map((key) => ({
                                                    value: key,
                                                    label: INQUIRY_TYPE_STATUS[key]?.title?.toUpperCase(),
                                                }))}
                                                variant="borderless"
                                                popupClassName="statusSelect"
                                                suffixIcon={null}
                                                className={`status ${statusClassName(dt.task.task_status)}`}
                                            />
                                        </div>
                                    </Flex>
                                    <div>
                                        <span>{formateDate(dt.task.due_date)}</span>
                                    </div>

                                    <div className="assigneebg">
                                        <Flex align="center" className="assignee-cell" style={{ width: "100%" }}>
                                            <Avatar.Group
                                                max={{
                                                    count: 3,
                                                    style: { backgroundColor: "#76a3da" },
                                                    popover: { rootClassName: "AsssignnePopover" },
                                                }}
                                                size={28}
                                            >
                                                {dt.task.assign_to.map((asn: any, idx: any) => {
                                                    return (
                                                        <Tooltip title={asn.full_name} key={idx}>
                                                            <Avatar
                                                                style={{
                                                                    backgroundColor: colors[idx % colors.length],
                                                                    marginLeft: "-6px",
                                                                }}
                                                            >
                                                                {getInitials(asn.full_name)}
                                                            </Avatar>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </Avatar.Group>
                                        </Flex>
                                        <UserInvite onSubmit={(val: any) => updateAssigneeList(val, dt.task._id)} assignList={assignList} assigned={dt.task.assign_to} />
                                    </div>
                                </div>
                            </List.Item>
                        );
                    })}
                </List>
            </div>
        </>
    );
}

export default WatchList;
