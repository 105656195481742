
export const antdVal = {
    token: {
        fontFamily: "Lato",
        fontSizeHeading3: 18,
    },
    components: {
        Layout: {
            headerPadding: 0,
            headerBg: "transparent",
            bodyBg: "transparent",
        },
        Segmented: {
            trackBg: "transparent",
            itemSelectedBg: "#1BC5BD",
            itemSelectedColor: "#1BC5BD",
            itemHoverBg: "#1BC5BD1A",
            itemHoverColor: "#1BC5BD",
            itemColor: "#6F7994",
            itemActiveBg: "#1bc5bd3b",
        },
        Button: {
            dangerColor: "#F8285A",
            defaultBg: "rgba(255, 255, 255, 0.08)",
            defaultActiveBg: "rgba(255, 255, 255, 0.08)",
            defaultBorderColor: "rgba(255, 255, 255, 0.4)",
            defaultHoverBorderColor: "rgba(255, 255, 255, 0.4)",
            defaultHoverBg: "#ffffff1f",
            paddingBlock: 7,
            paddingInline: 7,
        },
        Menu: {
            itemColor: "#fff",
            itemHoverColor: "#fff",
            itemSelectedColor: "#fff",
            itemHoverBg: "rgba(255, 255, 255, 0.08)",
            itemActiveBg: "transperent",
            itemSelectedBg: "rgba(255, 255, 255, 0.08)",
            itemBorderRadius: 10,
            itemSelectedBorderRadius: 10,
            fontSize: 15,
        },
        Select: {
            controlHeight: 38,
        },
        Divider: {
            colorSplit: "rgba(255, 255, 255, 1)",
        },
        Tooltip: {
            fontSize: 11,
            sizePopupArrow: 12,
        },
    },
};
