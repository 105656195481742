import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Button, Divider, Flex, Form, message, Tabs, TabsProps, Typography } from "antd";
import { AppDispatch, RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { setContactDrawerOpen, setContactOpenId } from "../../slices/layoutSlice";
import { DRAWER_TYPE } from "../../utils/const";
import DrawerCustom from "../../components/Drawer";
import ContactForm from "./ContactForm";
import ContactStyleCss from "./style.module.scss";
import FormStyleCss from "./ContactForm/form.module.scss";
import {
    addPassenger,
    ContactDataInterface,
    DocumentInterface,
    fetchPassengerList,
    getGroupDetails,
    GroupDataInterface,
    resetAllFormState,
    setInitDocHistory,
    setInitPassPortHistory,
    setMobileNumber,
    setPanCardImage,
    setPassPortImage,
    setProfileImage,
    setReferenceBy,
    updatePassengerList,
} from "../../slices/contactSlice";
import _ from "lodash";
import { getDocumentURL } from "../../utils/AWSService";
import dayjs from "dayjs";
import { setMinimizeItem } from "../../slices/taskSlice";
import { addItemInList, closeItem } from "../../slices/minimizeSlice";

const { Text } = Typography;
const { TabPane } = Tabs;
type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

interface ICreateContact {
    contactId?: string;
}

function CreateContact({ contactId }: ICreateContact) {
    const dispatch = useDispatch<AppDispatch>();
    const { contactDrawerOpen } = useSelector((state: RootState) => state.layout);
    const { profileImage, passPortImage, passPortHistory, panCardImage, otherDocHistory, groupData, loading, isPassportUploading } = useSelector(
        (state: RootState) => state.contact
    );
    const { user } = useSelector((state: RootState) => state.user);
    const { openItemList } = useSelector((state: RootState) => state.minimize);
    const currentOpen = openItemList[openItemList.length - 1];

    const [personList, setPersonList] = useState<any>([
        {
            label: "Main Person",
            key: `newTab0`,
            closable: false,
        },
    ]);
    const [existingList, setExistingList] = useState([]);
    const [activeKey, setActiveKey] = useState(personList[0]?.key);
    const [isSaved, setIsSaved] = useState(false);
    const newTabIndex = useRef(1);
    const [form] = Form.useForm();

    useEffect(() => {
        if (contactId === currentOpen?._id && contactId) {
            dispatch(getGroupDetails(contactId));
        } else {
            resetPersonList();
        }
    }, [contactId, currentOpen?._id]);

    useEffect(() => {
        if (groupData) {
            setFormData(groupData);
        } else {
            resetPersonList();
        }
    }, [groupData]);

    const resetPersonList = useCallback(() => {
        const initialList = [{ label: "Main Person", key: "newTab0", closable: false }];
        setPersonList(initialList);
        setActiveKey(initialList[0].key);
    }, []);

    const drawerClose = () => {
        if (contactId) {
            setTimeout(() => {
                dispatch(closeItem(contactId));
                setIsSaved(false);
                resetPersonList();
            }, 300);
        } else {
            dispatch(resetAllFormState());
            form.resetFields();
            setExistingList([]);
            dispatch(setContactDrawerOpen(null));
        }
    };

    const dataSaved = () => {
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 2000);
    };

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "General",
            children: "",
        },
        {
            key: "2",
            label: "Invoices",
            children: "",
        },
        {
            key: "3",
            label: "Deals",
            children: "",
        },
        {
            key: "4",
            label: "History",
            children: "",
        },
        {
            key: "5",
            label: "More",
            children: "",
        },
    ];

    const handleMinimize = () => {
        if (contactId) {
            dispatch(
                addItemInList({
                    name: form.getFieldValue([contactId, "contact_name"]),
                    _id: contactId,
                    type: "CONTACT",
                })
            );
            drawerClose();
        }
    };

    const onTabChange = (val: any) => {
        console.log(val);
    };

    const otherDocName = (val: string) => {
        return _.startCase(_.toLower(_.trim(val)));
    };

    const handleFinish = async (val: any) => {
        const formData = new FormData();

        const appendFile = (formData: FormData, fileKey: string, file: any) => {
            if (file?.originFileObj) {
                formData.append(fileKey, file.originFileObj);
            }
        };

        Object?.keys(val)?.forEach((key: any, index: number) => {
            const item = val[key];
            const valueOf = key;
            const prefix = !key?.includes("newTab") ? `${key}` : key === "newTab0" ? "main_person" : `person_${index}`;
            const passportBackData = passPortImage?.[valueOf]?.back?.[0];
            const passportFrontData = passPortImage?.[valueOf]?.front?.[0];
            const panCardData = panCardImage?.[valueOf]?.[0];
            const passportHistory = passPortHistory[valueOf]?.history;
            const otherDoc = otherDocHistory[valueOf]?.history;

            const personObject: ContactDataInterface = {
                contact_name: item?.contact_name,
                full_name: item?.full_name,
                contact_number: item?.contact_number,
                address: {
                    residents_address: item?.residents_address,
                    city: item?.city,
                    state: item?.state,
                    country: item?.country,
                    pincode: item?.pincode,
                },
                documents: {
                    passport: {
                        passport_number: item?.passport_number,
                        date_of_birth: item?.dob,
                        last_name: item?.passport_last_name,
                        first_name: item?.passport_first_name,
                        middle_name: item?.passport_middle_name,
                        passport_issue_date: item?.passport_issue_date,
                        passport_exp_date: item?.passport_expiry_date,
                        father_name: item?.passport_father_name,
                        mother_name: item?.passport_mother_name,
                        spouse_name: item?.passport_spouse_name,
                    },
                    pan_card: {
                        pan_number: item?.pancard_number,
                        name: item?.pancard_name,
                    },
                },
            };
            if (!!item?.email) {
                personObject.email = item?.email;
            }
            if (!!item?.reference_by) {
                personObject.reference_by = item?.reference_by;
            }

            if (!!item?.gender) {
                _.set(personObject, "documents.passport.gender", item?.gender);
            }

            if (passportBackData && !passportBackData?.hasOwnProperty("originFileObj")) {
                _.set(personObject, "documents.passport.back_image", {
                    doc_type: passportBackData?.doc_type,
                    file_name: passportBackData?.file_name,
                });
            }
            if (passportFrontData && !passportFrontData?.hasOwnProperty("originFileObj")) {
                _.set(personObject, "documents.passport.front_image", {
                    doc_type: passportFrontData?.doc_type,
                    file_name: passportFrontData?.file_name,
                });
            }

            if (panCardData && !panCardData?.hasOwnProperty("originFileObj")) {
                _.set(personObject, "documents.pan_card.image", {
                    doc_type: panCardData?.doc_type,
                    file_name: panCardData?.file_name,
                });
            }

            if (passportHistory?.length > 0) {
                const oldPassHistory = passportHistory
                    ?.filter((data: any) => !data?.file)
                    ?.map((data: any) => {
                        return {
                            file_name: data?.file_name,
                            id: data?.id,
                            exp_date: data?.expDate,
                        };
                    });
                _.set(personObject, "documents.passport.passport_history", oldPassHistory);
            }

            if (otherDoc?.length > 0) {
                const oldOtherDoc = otherDoc
                    ?.filter((data: any) => !data?.file)
                    ?.map((data: any) => ({
                        file_name: data?.fileName,
                        id: data?.id,
                        upload_date: data?.uploadDate,
                        doc_name: data?.name,
                        doc_type: data?.docType,
                    }));
                _.set(personObject, "documents.other_documents", oldOtherDoc);
            }

            if (_.isEmpty(_.get(personObject, "address", {}))) {
                delete personObject.address;
            }
            if (_.isEmpty(_.get(personObject, "documents.passport", {}))) {
                _.set(personObject, "documents.passport", undefined);
            }

            if (_.isEmpty(_.get(personObject, "documents.pan_card", {}))) {
                delete personObject.documents?.pan_card;
            }
            if (_.isEmpty(_.get(personObject, "documents", {}))) {
                delete personObject.documents;
            }

            appendFile(formData, `${prefix}_profile`, profileImage?.[valueOf]?.[0]);
            appendFile(formData, `${prefix}_passport_front`, passportFrontData);
            appendFile(formData, `${prefix}_passport_back`, passportBackData);
            appendFile(formData, `${prefix}_pan`, panCardImage?.[valueOf]?.[0]);

            if (otherDocHistory[valueOf]?.history?.length > 0) {
                otherDocHistory[valueOf]?.history?.forEach((doc: any) => {
                    const docName = doc?.docType && doc?.docType?.replace(" ", "_")?.toLowerCase();
                    appendFile(formData, `${prefix}_other_${docName}`, doc?.file);
                });
            }
            if (passPortHistory[valueOf]?.history?.length > 0) {
                passPortHistory[valueOf]?.history?.forEach((passport: any) => appendFile(formData, `${prefix}_passport_history_${passport?.expDate}`, passport?.file));
            }

            if (Object.keys(personObject)?.length > 0) {
                formData.append(`${prefix}`, JSON.stringify(personObject));
            }
        });

        if (contactId && groupData) {
            const response: any = await dispatch(updatePassengerList({ id: groupData?._id, updatedData: formData }));
            if (response) {
                dataSaved();
                dispatch(fetchPassengerList());
            }
        } else {
            const response: any = await dispatch(addPassenger(formData));
            if (!response.payload) {
                drawerClose();
                dispatch(fetchPassengerList());
            } else {
                message.error(response.payload.message);
                setExistingList(response.payload.data);
            }
        }
    };

    const handleFinishFailed = (errorInfo: any) => {
        if (errorInfo && errorInfo?.errorFields?.length > 0) {
            let activeKey = errorInfo?.errorFields[0]?.name[0];
            setActiveKey(activeKey.toString());
            message.error("Please enter data in all required fields.");
        }
    };

    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    const add = () => {
        const key = newTabIndex.current++;
        const newActiveKey = `newTab${key}`;
        const newPanes = [...personList];
        newPanes?.push({ label: `Person ${key}`, children: "Content of new Tab", key: newActiveKey });
        setPersonList(newPanes);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey: TargetKey) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        personList?.forEach((item: any, i: number) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = personList.filter((item: any) => item.key !== targetKey);
        if (newPanes?.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        setPersonList(newPanes);
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
        if (action === "add") {
            add();
        } else {
            remove(targetKey);
        }
    };

    // const onValuesChange = (changedValues: any, allValues: any) => {
    //     dispatch(setFormData(allValues));
    //     console.log("Current form data in Redux:", allValues);
    // };

    const setFormData = (groupData: GroupDataInterface) => {
        const { main_person, group_member } = groupData;
        let tabList = [
            {
                label: main_person?.full_name,
                key: main_person?._id,
                closable: false,
            },
        ];

        setFieldValues(main_person);
        _.forEach(group_member, (grpMbr: ContactDataInterface) => {
            tabList.push({ label: grpMbr?.full_name, key: grpMbr?._id, closable: false });
            setFieldValues(grpMbr);
        });
        dispatch(setMobileNumber(main_person?.contact_number));
        if (!!main_person?.reference_by) {
            dispatch(setReferenceBy(main_person?.reference_by as string));
        }

        setPersonList(tabList);
        setActiveKey(contactId);
    };

    const setFieldValues = (person: ContactDataInterface) => {
        const { _id, address, documents, profile_image } = person;

        if (_id) {
            form.setFieldValue([_id, "contact_name"], _.get(person, "contact_name", ""));
            form.setFieldValue([_id, "full_name"], _.get(person, "full_name", ""));
            form.setFieldValue([_id, "email"], _.get(person, "email", ""));
            form.setFieldValue([_id, "contact_number"], _.get(person, "contact_number", ""));
            form.setFieldValue([_id, "reference_by"], _.get(person, "reference_by", ""));

            if (address) {
                form.setFieldValue([_id, "residents_address"], _.get(address, "residents_address", ""));
                form.setFieldValue([_id, "city"], _.get(address, "city", ""));
                form.setFieldValue([_id, "pincode"], _.get(address, "pincode", ""));
                form.setFieldValue([_id, "state"], _.get(address, "state", ""));
                form.setFieldValue([_id, "country"], _.get(address, "country", ""));
            }

            const { passport, pan_card } = documents || {};
            if (passport) {
                form.setFieldValue([_id, "passport_number"], _.get(passport, "passport_number", ""));
                form.setFieldValue([_id, "dob"], _.get(passport, "date_of_birth") ? dayjs(_.get(passport, "date_of_birth")) : "");
                form.setFieldValue([_id, "gender"], _.get(passport, "gender", ""));
                form.setFieldValue([_id, "passport_last_name"], _.get(passport, "last_name", ""));
                form.setFieldValue([_id, "passport_first_name"], _.get(passport, "first_name", ""));
                form.setFieldValue([_id, "passport_middle_name"], _.get(passport, "middle_name", ""));
                form.setFieldValue([_id, "passport_issue_date"], _.get(passport, "passport_issue_date") ? dayjs(_.get(passport, "passport_issue_date")) : null);
                form.setFieldValue([_id, "passport_expiry_date"], _.get(passport, "passport_exp_date") ? dayjs(_.get(passport, "passport_exp_date")) : null);
                form.setFieldValue([_id, "passport_father_name"], _.get(passport, "father_name", ""));
                form.setFieldValue([_id, "passport_mother_name"], _.get(passport, "mother_name", ""));
                form.setFieldValue([_id, "passport_spouse_name"], _.get(passport, "spouse_name", ""));

                const passportFront = _.get(passport, "front_image.url")
                    ? [
                          {
                              doc_type: _.get(passport, "front_image.doc_type"),
                              file_name: _.get(passport, "front_image.file_name"),
                              url: _.get(passport, "front_image.url"),
                          },
                      ]
                    : [];
                const passportBack = _.get(passport, "back_image.url")
                    ? [
                          {
                              doc_type: _.get(passport, "back_image.doc_type"),
                              file_name: _.get(passport, "back_image.file_name"),
                              url: _.get(passport, "back_image.url"),
                          },
                      ]
                    : [];

                form.setFieldValue([_id, "passportFront"], passportFront);
                form.setFieldValue([_id, "passportBack"], passportBack);

                dispatch(setPassPortImage({ formId: _id, images: passportFront, type: "front" }));
                dispatch(setPassPortImage({ formId: _id, images: passportBack, type: "back" }));

                if (passport.passport_history) {
                    dispatch(
                        setInitPassPortHistory({
                            formId: _id,
                            data: _.map(passport?.passport_history, (pass: DocumentInterface) => {
                                return {
                                    id: pass?._id,
                                    file_name: pass?.file_name,
                                    name: pass?.file_name,
                                    expDate: pass?.exp_date,
                                    url: getDocumentURL(`passenger_doc/${user?.tenant_id}/${_id}/${pass?.file_name}`),
                                };
                            }),
                        })
                    );
                }
            }

            if (pan_card) {
                form.setFieldValue([_id, "pancard_name"], _.get(pan_card, "name", ""));
                form.setFieldValue([_id, "pancard_number"], _.get(pan_card, "pan_number", ""));

                const panCard = _.get(pan_card, "image.url")
                    ? [{ doc_type: _.get(pan_card, "image.doc_type"), file_name: _.get(pan_card, "image.file_name"), url: _.get(pan_card, "image.url") }]
                    : [];

                form.setFieldValue([_id, "panCard"], panCard);
                dispatch(setPanCardImage({ formId: _id, images: panCard }));
            }

            if (documents?.other_documents) {
                dispatch(
                    setInitDocHistory({
                        formId: _id,
                        data: _.map(documents.other_documents, (doc: DocumentInterface) => ({
                            id: doc?._id,
                            name: otherDocName(doc?.doc_name || ""),
                            fileName: doc?.file_name,
                            uploadDate: doc?.upload_date,
                            url: getDocumentURL(`passenger_doc/${user?.tenant_id}/${_id}/${doc?.file_name}`),
                            docType: doc?.doc_type,
                        })),
                    })
                );
            }

            if (!!profile_image) {
                dispatch(
                    setProfileImage({
                        formId: _id,
                        images: {
                            name: profile_image,
                            url: getDocumentURL(`passenger_doc/${user?.tenant_id}/${_id}/${profile_image}`),
                        },
                    })
                );
            }
        }
    };

    return (
        <>
            <DrawerCustom
                handleMinimize={handleMinimize}
                drawerCloseHandle={drawerClose}
                open={contactDrawerOpen === DRAWER_TYPE.CONTACT_DRAWER || contactId ? true : false}
                width={820}
                closeText="CONTACT"
                showSavedStatus={contactId ? true : false}
                isLoading={loading}
                isDataUpdated={isSaved}
                showMinimize={contactId ? true : false}
            >
                <div style={{ padding: "23px 12px" }}>
                    <Text className="drawer-title">{contactId ? "Contact Detail" : "New Contact"}</Text>
                </div>
                <Tabs className={ContactStyleCss.mainTab} defaultActiveKey="1" items={items} onChange={onTabChange} />
                {existingList?.length > 0 && (
                    <Alert message={`This User ${existingList?.map((usr: any) => ` ${usr.name}`)} Contact Number Alreasdy Exist`} type="error" style={{ marginBottom: 12 }} />
                )}
                <Form
                    form={form}
                    name="dynamic_form"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={handleFinish}
                    onFinishFailed={handleFinishFailed}
                    scrollToFirstError
                    // onValuesChange={(val: any) => console.log(val)}
                >
                    <Tabs className={ContactStyleCss.contactFormTab} type="editable-card" onChange={onChange} activeKey={activeKey} onEdit={onEdit}>
                        {personList?.map((per: any, index: number) => (
                            <TabPane closable={per.closable} tab={per.label} key={per.key} tabKey={`${per.key}`}>
                                <ContactForm form={form} formId={per.key} />
                                <Divider
                                    style={{
                                        borderBlockStart: "1px solid rgba(219, 223, 233, 1)",
                                        margin: 0,
                                    }}
                                />
                                <div className="contact-form-footer">
                                    <Flex gap={15} justify="end">
                                        <Button className={FormStyleCss.cancelButton} onClick={drawerClose}>
                                            Cancel
                                        </Button>
                                        <Button className={FormStyleCss.formButton} htmlType="submit" loading={loading || isPassportUploading.front || isPassportUploading.back}>
                                            Save
                                        </Button>
                                    </Flex>
                                </div>
                            </TabPane>
                        ))}
                    </Tabs>
                </Form>
            </DrawerCustom>
        </>
    );
}

export default CreateContact;
