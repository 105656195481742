import { createContext, useContext, useEffect, useState } from "react";
import { Badge, Button, Dropdown, Form, MenuProps, Space, TabsProps } from "antd";
import dayjs from "dayjs";
import { AppDispatch, RootState } from "../../store";
import { useSelector } from "react-redux";
import useTeamMember from "../../hooks/useTeamMember";
import webSocketService from "../../utils/websocketService";
import AxiosService from "../../utils/APIService";
import { DRAWER_TYPE, TASK_STATUS } from "../../utils/const";
import SearchHeader from "../../components/SearchHeader";
import TabLayout from "./TaskHeader/TaskTab";
import TaskNav from "./TaskHeader/TaskNav";
import CreateTask from "./CreateDrawer/CreateTask";
import Task from "./Task";
import WishList from "./WatchList";
import CompetedList from "./CompetedList";
import DeleteRequest from "./DeleteRequest";
import DeletedList from "./DeletedList";
import Taskcss from "./style.module.scss";
import { FaCaretDown } from "react-icons/fa6";
import { setDrawerOpen } from "../../slices/layoutSlice";
import { useDispatch } from "react-redux";
import commonCSS from "../../css/commonStyle.module.scss";
import CreateInquiry from "./CreateDrawer/CreateInquiry";

const InquryContext: any = createContext({});
const _ = require("lodash");

export const useInquiry = () => {
    return useContext(InquryContext);
};

function Inquiry() {
    const { user } = useSelector((state: RootState) => state.user);
    const apiService = new AxiosService();
    const [taskList, setTaskList] = useState<any>();
    const [taskTab, setTaskTab] = useState(() => localStorage.getItem("taskTab") || "2");
    const [pendingTasks, setPendingTasks] = useState<any[]>([]);
    const [WatchList, setWatchList] = useState<any[]>([]);
    const [CompletedList, setCompletedList] = useState<any[]>([]);
    const [deleteRequestList, setDeleteRequestList] = useState<any[]>([]);
    const [deletedList, setDeletedList] = useState<any[]>([]);
    const [form] = Form.useForm();
    const [filter, setFilter] = useState<any>({ is_personal_task: false });
    const { assignList, fetchAssignList } = useTeamMember();
    const [searchText, setSearchText] = useState<string>("");
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        fetchAssignList();
        fetchPendingTask();
        fetchWatchlist();
        fetchTaskList();
        fetchCompletedList();
        fetchDeleteRequestlist();
        fetchDeletedList();
    }, []);

    useEffect(() => {
        webSocketService.onMessage("taskPositionUpdate", (val) => {
            if (val.tenantId === user?.tenant_id) {
                setTimeout(() => {
                    fetchTaskList(filter);
                }, 200);
                fetchPendingTask();
                fetchWatchlist();
                fetchDeleteRequestlist();
                fetchCompletedList();
                fetchDeletedList();
            }
        });
    }, [user]);

    useEffect(() => {
        if (filter) {
            const timeoutIds = [setTimeout(fetchDeleteRequestlist, 100), setTimeout(fetchDeletedList, 100)];
            return () => timeoutIds.forEach(clearTimeout);
        }
    }, [filter]);

    useEffect(() => {
        if (filter && user) {
            fetchTaskList();
        }
    }, [filter, user]);

    useEffect(() => {
        webSocketService.onMessage("watchListRefresh", (val) => {
            if (val.tenantId === user?.tenant_id) {
                fetchDeletedList();
            }
        });
    }, [user]);

    const parseDate = (dateStr: string) => {
        const date: any = dayjs(dateStr, "DD-MM-YYYY HH:mm");
        return new Date(date);
    };

    const fetchTaskList = (params?: any) => {
        apiService
            .get("/task/list")
            .then((res: any) => {
                const data = res.data;
                const listObj = {
                    Today: data
                        .filter((dt: any) => dt?.task?.status === TASK_STATUS.inprogress)
                        .sort((a: any, b: any) => parseDate(a.task.due_date).getTime() - parseDate(b.task.due_date).getTime()),
                    "Up-coming": data.filter((dt: any) => dt?.task?.status === TASK_STATUS.pending).sort((a: any, b: any) => a.task.listOrde - b.task.listOrde),
                };

                if (filter?.is_personal_task && user) {
                    listObj.Today = listObj.Today.filter((dt: any) => dt.task.assign_to.some((usr: any) => usr._id === user?._id));
                    listObj["Up-coming"] = listObj["Up-coming"].filter((dt: any) => dt.task.assign_to.some((usr: any) => usr._id === user?._id));
                }
                setTaskList(listObj);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const fetchPendingTask = () => {
        apiService
            .get("/task/pending-list")
            .then((res: any) => {
                setPendingTasks(res.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const fetchWatchlist = () => {
        apiService
            .get("/task/list", {
                status: TASK_STATUS.completedbyUser,
                ...filter,
            })
            .then((res: any) => {
                setWatchList(res.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const fetchCompletedList = () => {
        apiService
            .get("/task/list", {
                status: TASK_STATUS.complete,
                ...filter,
            })
            .then((res: any) => {
                setCompletedList(res.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const fetchDeleteRequestlist = () => {
        apiService
            .get("/task/list", {
                status: "REQUEST_FOR_DELETE",
                ...filter,
            })
            .then((res: any) => {
                setDeleteRequestList(res.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const fetchDeletedList = () => {
        apiService
            .get("/task/list", {
                isDeleted: true,
                ...filter,
            })
            .then((res: any) => {
                setDeletedList(res.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const TabsList: TabsProps["items"] = [
        {
            key: "2",
            label: (
                <>
                    Task
                    {taskList && (taskList.Today?.length || 0) + (taskList["Up-coming"]?.length || 0) > 0 && (
                        <Badge
                            count={(taskList.Today?.length || 0) + (taskList["Up-coming"]?.length || 0)}
                            size="small"
                            overflowCount={99}
                            offset={[3, -13]}
                            style={{ fontSize: "10px" }}
                        />
                    )}
                </>
            ),
            children: "",
        },
        {
            key: "3",
            label: (
                <>
                    Watchlist
                    {WatchList?.length > 0 && <Badge count={WatchList?.length} size="small" overflowCount={99} offset={[3, -13]} style={{ fontSize: "10px" }} />}
                </>
            ),
            children: "",
        },
        {
            key: "4",
            label: (
                <div className={Taskcss.badge}>
                    Completed
                    {CompletedList?.length > 0 && <Badge count={CompletedList?.length} size="small" overflowCount={99} offset={[3, -13]} style={{ fontSize: "10px" }} />}
                </div>
            ),
            children: "",
        },
        ...(deleteRequestList?.length === 0
            ? []
            : [
                  {
                      key: "5",
                      label: (
                          <>
                              Delete Request
                              {deleteRequestList?.length > 0 && (
                                  <Badge count={deleteRequestList?.length} size="small" overflowCount={99} offset={[3, -13]} style={{ fontSize: "10px" }} />
                              )}
                          </>
                      ),
                      children: "",
                  },
              ]),
        { key: "6", label: "Deleted", children: "" },
    ];

    const handleTabChange = (key: string) => {
        setTaskTab(key);
        localStorage.setItem("taskTab", key);
    };

    useEffect(() => {
        form.setFieldValue("authority", true);
        form.setFieldValue("dueDate", dayjs());
    });

    const items: MenuProps["items"] = [
        {
            key: "1",
            label: "Create Task",
            onClick: () => dispatch(setDrawerOpen(DRAWER_TYPE.CREATE_TASK)),
        },
        // {
        //     key: "2",
        //     label: "Create Inquiry",
        //     onClick: () => dispatch(setDrawerOpen(DRAWER_TYPE.CONTACT_DRAWER)),
        // },
    ];

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const filterTasks = (tasks: any[]) => tasks.filter((task: any) => task.task.title.toLowerCase().includes(searchText.toLowerCase()));

    const filteredTaskList = taskList && {
        Today: filterTasks(taskList.Today),
        "Up-coming": filterTasks(taskList["Up-coming"]),
    };

    const filteredWatchList = filterTasks(WatchList);
    const filteredCompletedList = filterTasks(CompletedList);
    const filteredDeleteRequestList = filterTasks(deleteRequestList);
    const filteredDeletedList = filterTasks(deletedList);

    const handleCreateClick = () => {
        dispatch(setDrawerOpen(DRAWER_TYPE.CREATE_TASK));
    };

    return (
        <>
            <InquryContext.Provider
                value={{
                    assignList,
                }}
            >
                <TabLayout currentValue={taskTab} items={TabsList} onChange={handleTabChange} />

                <SearchHeader title={"My Tasks"} searchValue={searchText} onSearchChange={handleSearchChange}>
                    <Dropdown.Button
                        menu={{ items }}
                        placement="bottomLeft"
                        arrow={{ pointAtCenter: true }}
                        icon={<FaCaretDown />}
                        onClick={handleCreateClick}
                        className={commonCSS.inquireyBtn}
                    >
                        CREATE
                    </Dropdown.Button>
                </SearchHeader>

                <TaskNav setFilter={setFilter} filterData={filter} />
                {taskTab === "2" && <Task pendingTasks={pendingTasks} taskList={filteredTaskList} setTaskList={setTaskList} />}
                {taskTab === "3" && <WishList filter={filter} WatchList={filteredWatchList} />}
                {taskTab === "4" && <CompetedList filter={filter} CompletedList={filteredCompletedList} />}
                {taskTab === "5" && <DeleteRequest deleteRequestList={filteredDeleteRequestList} />}
                {taskTab === "6" && <DeletedList filter={filter} deletedList={filteredDeletedList} />}
                <CreateTask />
                <CreateInquiry />
            </InquryContext.Provider>
        </>
    );
}

export default Inquiry;
