import { useEffect, useState } from "react";
import { Button, Flex, Space, Typography } from "antd";
import styleCss from "./style.module.scss";
import commonCss from "../../css/commonStyle.module.scss";
import NavTab from "../../components/NavTab";
import NoteList from "./NoteList";
import { MdClose } from "react-icons/md";
import { ArchiveForBulk, BellIcon, ColorPickerIcon, DeleteForEver, MoreOptionsIcon, PinNotes, RestoreFromBin, UnArchiveForBulk } from "./NotesFooterIcons";
import { NOTES_FILTER_TYPE, NOTES_FILTER_VIEW, NOTES_FOOTER_TYPE } from "../../utils/const";
import ReminderNoteList from "./ReminderNoteList";
import BinNoteList from "./BinNoteList";
import ArchiveNoteList from "./ArchiveNoteList";
import {
    bulkColorChange,
    bulkReminder,
    fetchAllLabel,
    fetchAllNotes,
    fetchArchiveNoteList,
    fetchBinNoteList,
    ISelectedLabels,
    resetSelectedNotes,
    setActiveFilterTab,
} from "../../slices/notesSlice";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { getNoteIds } from "../../utils/commonFunctions";
import EditLabel from "./EditLabel";
import _ from "lodash";

function Notes() {
    const dispatch = useDispatch<AppDispatch>();
    const { selectedNotes, activeFilterTab } = useSelector((state: RootState) => state.notes);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectedLabels, setSelectedLabels] = useState<ISelectedLabels[]>([]);
    const [isRemainderOpen, setIsRemainderOpen] = useState(false);

    const fetchList = () => {
        dispatch(fetchAllNotes());
    };

    useEffect(() => {
        fetchList();
        dispatch(fetchAllLabel());
    }, []);

    useEffect(() => {
        dispatch(resetSelectedNotes());

        if (_.isEqual(activeFilterTab, NOTES_FILTER_TYPE.bin)) {
            dispatch(fetchBinNoteList());
        } else if (_.isEqual(activeFilterTab, NOTES_FILTER_TYPE.archive)) {
            dispatch(fetchArchiveNoteList());
        } else if (_.isEqual(activeFilterTab, NOTES_FILTER_TYPE.notes)) {
            fetchList();
        }
    }, [activeFilterTab]);

    const viewItem = [
        {
            key: "1",
            label: "List view",
            children: "",
        },
        {
            key: "2",
            label: "Grid view",
            children: "",
        },
    ];

    const onChange = (key: string) => {
        if (_.isEqual(key, NOTES_FILTER_TYPE.editLabel)) {
            setTimeout(() => {
                setIsModalVisible(true);
            }, 0);
        } else {
            dispatch(setActiveFilterTab(key));
        }
    };

    const onColorChange = async (selectedColor: string) => {
        const noteIds = getNoteIds(selectedNotes);
        await dispatch(bulkColorChange({ ids: noteIds, color: selectedColor }));
        await dispatch(fetchAllNotes());
    };

    const handleBulkReminder = async (date: string) => {
        const reminder = _.isDate(date) ? date : _.isString(date) ? new Date(date) : null;
    
        if (reminder && !isNaN(reminder.getTime())) {
            await dispatch(bulkReminder({ ids: getNoteIds(selectedNotes), reminder }));
            await dispatch(fetchAllNotes());
            setIsRemainderOpen(false);
        } else {
            console.error('Invalid date provided:', date);
        }
    };

    return (
        <>
            <Flex justify="space-between" align="center" style={{ marginTop: 2 }} className={commonCss.mytaskBlock}>
                <Space size={20} align="center">
                    <Typography.Title level={3} className={commonCss.pageTitle}>
                        Notes
                    </Typography.Title>
                    <div className={styleCss.LeftTab}>
                        <NavTab items={viewItem} />
                    </div>
                </Space>
                <div className={styleCss.rightTab}>
                    <NavTab items={NOTES_FILTER_VIEW} onChange={onChange} activeKey={activeFilterTab} defaultActiveKey={NOTES_FILTER_TYPE.notes} />
                </div>
            </Flex>

            <div className={styleCss.noteView}>
                <div className={`${styleCss.selectDrawer} ${selectedNotes.length > 0 ? styleCss.show : ""}`}>
                    <Flex align="center" justify="space-between">
                        <Flex align="center">
                            <Button onClick={() => dispatch(resetSelectedNotes())} className={styleCss.closeBtn} shape="circle" type="text" icon={<MdClose size={25} />} />
                            <Typography.Text className={styleCss.selectText}>{selectedNotes.length} selected</Typography.Text>
                        </Flex>
                        <Flex align="center" gap={10} style={{ paddingRight: 20 }} className="bulkBtn">
                            {activeFilterTab !== NOTES_FILTER_TYPE.bin ? (
                                <>
                                    <PinNotes />
                                    <BellIcon
                                        isRemainderOptionOpen={isRemainderOpen}
                                        setIsRemainderOptionOpen={setIsRemainderOpen}
                                        reminder={""}
                                        setReminder={handleBulkReminder}
                                        footerType={NOTES_FOOTER_TYPE.bulkAction}
                                    />
                                    <ColorPickerIcon onColorChange={onColorChange} footerType={NOTES_FOOTER_TYPE.bulkAction} />
                                    {activeFilterTab !== NOTES_FILTER_TYPE.archive ? <ArchiveForBulk /> : <UnArchiveForBulk />}
                                    <MoreOptionsIcon
                                        isMoreOptionOpen={false}
                                        selectedLabels={selectedLabels}
                                        setSelectedLabels={setSelectedLabels}
                                        footerType={NOTES_FOOTER_TYPE.bulkAction}
                                    />
                                </>
                            ) : (
                                <>
                                    <DeleteForEver id={""} footerType={NOTES_FOOTER_TYPE.bulkAction} />
                                    <RestoreFromBin id={""} footerType={NOTES_FOOTER_TYPE.bulkAction} />
                                </>
                            )}
                        </Flex>
                    </Flex>
                </div>
                {activeFilterTab === NOTES_FILTER_TYPE.notes && <NoteList />}
                {activeFilterTab === NOTES_FILTER_TYPE.reminders && <ReminderNoteList />}
                {activeFilterTab === NOTES_FILTER_TYPE.archive && <ArchiveNoteList />}
                {activeFilterTab === NOTES_FILTER_TYPE.bin && <BinNoteList />}
            </div>

            <EditLabel isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
        </>
    );
}

export default Notes;
