import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { Button, Card, Flex, Form } from "antd";
import { InputBox } from "../../components/FormInput";
import AxiosService from "../../utils/APIService";
import { LocalStorageService } from "../../utils/LocalStorage";
import { UTILS } from "../../utils/const";
import { isLogin, setProfileData, setUserData } from "../../slices/userSlice";

interface LoginValueInterface {
    email: String;
    password: String;
}

function Login() {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const apiService = new AxiosService();

    const onFinish = (value: LoginValueInterface) => {
        apiService
            .post("/auth/sign-in", value)
            .then((res: any) => {
                dispatch(isLogin(true));
                dispatch(setUserData(res.user));
                dispatch(setProfileData({ ...res.profile, _id: undefined }));
                LocalStorageService.setItem(UTILS.token, res?.token);
                LocalStorageService.setItem(UTILS.user, res?.user);

                navigate("/inquiry");
                // if(res?.user?.role === ROLE.agentUser){
                // }else if(res?.user?.role === ROLE.agentAdmin){
                //     navigate('/team')
                // }
            })
            .catch(() => {
                dispatch(isLogin(false));
                dispatch(setUserData(null));
                dispatch(setProfileData(null));
            });
    };
    return (
        <Flex justify="center" align="center" style={{ height: "100vh" }}>
            <Card
                style={{
                    maxWidth: 500,
                    width: "100%",
                }}
            >
                <Form name="Login" onFinish={onFinish} layout="vertical">
                    <InputBox name={"email"} label="Email" rules={[{ required: true, message: "Please Enter Email!" }]} />
                    <InputBox type="password" name={"password"} label="Password" rules={[{ required: true, message: "Please Enter Password!" }]} />
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={false}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Flex>
    );
}

export default Login;
