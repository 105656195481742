import { UploadOutlined } from "@ant-design/icons";
import { Button, ColorPicker, DatePicker, Form, Input, InputNumber, Upload } from "antd";
import React, { forwardRef } from "react";
export interface PropsInterface {
    style?: React.CSSProperties;
    placeholder?: string;
    isUppercase?: boolean;
    label?: string;
    name?: any;
    rules?: any;
    type?: "number" | "text" | "password" | "textarea" | "date" | "colorpicker" | "upload";
    className?: string;
    autoSize?: object;
    dependencies?: any;
    disabled?: boolean;
    variant?: "outlined" | "borderless" | "filled" | undefined;
    minDate?: any;
    showTime?: any;
    defaultValue?: any;
    onBlur?: (val: any) => void;
    tabIndex?: number;
    onChange?: any;
    onFocus?: (val: any) => void;
    ref?: any;
    value?: any;
}
const InputBox = forwardRef<any, PropsInterface>(
    ({ placeholder, isUppercase, type, autoSize, disabled, variant, minDate, style, showTime, defaultValue, onBlur, onChange, tabIndex, onFocus, value, ...rest }, ref) => {
        const toUppercase = (e: any) => {
            return (e.target.value = e.target.value?.toUpperCase());
        };
        return (
            <>
                <Form.Item {...rest}>
                    {type == "number" ? (
                        <InputNumber onChange={onChange} disabled={disabled} placeholder={placeholder ? placeholder : `Enter ${rest.label}`} size="large" />
                    ) : type == "password" ? (
                        <Input.Password onChange={onChange} disabled={disabled} placeholder={placeholder ? placeholder : `Enter ${rest.label}`} size="large" />
                    ) : type == "textarea" ? (
                        <Input.TextArea
                            ref={ref}
                            disabled={disabled}
                            placeholder={placeholder ? placeholder : `Enter ${rest.label}`}
                            autoSize={autoSize}
                            size="large"
                            onBlur={onBlur}
                            onChange={onChange}
                            tabIndex={tabIndex}
                            variant={variant}
                            onFocus={onFocus}
                        />
                    ) : type == "date" ? (
                        <DatePicker
                            showTime={showTime}
                            disabled={disabled}
                            placeholder={placeholder ? placeholder : `DD-MM-YYYY`}
                            size="large"
                            format={{
                                format: showTime ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY",
                                type: "mask",
                            }}
                            style={{ ...style, width: "100%" }}
                            minDate={minDate}
                            defaultValue={defaultValue}
                            value={value}
                            onChange={onChange}
                        />
                    ) : type == "colorpicker" ? (
                        <ColorPicker onChange={onChange} defaultValue="#F8285A" showText style={{ justifyContent: "start" }} />
                    ) : type == "upload" ? (
                        <Upload beforeUpload={() => false}>
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    ) : (
                        <Input
                            variant={variant}
                            placeholder={placeholder ? placeholder : `Enter ${rest.label}`}
                            onInput={(e: any) => {
                                return isUppercase ? toUppercase(e) : e.target.value;
                            }}
                            onChange={onChange}
                            size="large"
                            disabled={disabled}
                        />
                    )}
                </Form.Item>
            </>
        );
    }
);

export default InputBox;
