import { Avatar, Button, Flex, List, Space, Typography, Dropdown, MenuProps, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MenuOutlined, SettingOutlined } from "@ant-design/icons";
import Icon from "./Icon";
import AxiosService from "../../utils/APIService";
import fireActiveIcon from "../../assets/fireactive.svg";
import { INQUIRY_TYPE, TASK_STATUS } from "../../utils/const";
import { formateDate, getInitials, statusClassName } from "../../utils/commonFunctions";
import Taskcss from "./style.module.scss";

interface PendingTaskInterface {
    deleteRequestList: any[];
}

const colors = ["#76a3da"];

const DeleteRequest = ({ deleteRequestList }: PendingTaskInterface) => {
    const { user } = useSelector((state: RootState) => state.user);
    const apiService = new AxiosService();

    const items: MenuProps["items"] = [
        {
            key: TASK_STATUS.cancel,
            label: "Cancel Request",
        },
    ];

    const adminDropdownItem: MenuProps["items"] = [
        {
            key: "ACCEPT",
            label: "Accept",
        },
        {
            key: "REJECT",
            label: "Reject",
        },
    ];

    const handleTaskMove = (status: any, id: string) => {
        if (user?.role === "AGENT_ADMIN") {
            apiService
                .post(`/task/delete-request-action`, {
                    taskId: id,
                    action: status.key,
                })
                .then((res) => {})
                .catch((e) => console.log(e));
        } else {
            apiService
                .post(`/task/revert-delete-request`, {
                    taskId: id,
                })
                .then((res) => {})
                .catch((e) => console.log(e));
        }
    };

    return (
        <>
            <div className={Taskcss.taskCollaps}>
                <div className="task-header">
                    <span className="length-count">{String(deleteRequestList?.length)?.padStart(2, "0")}</span>
                    <span>Deleted Request</span>
                </div>
                <List size="small" bordered>
                    <List.Item>
                        <div className="task-grid-wrapper header" style={{ gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)" }}>
                            <Space>
                                <SettingOutlined style={{ lineHeight: "1", marginLeft: "35px", color: "#6f7994a3", marginRight: "1px" }} />
                                <span>NAME</span>
                            </Space>
                            <div>
                                <span>DUE DATE</span>
                            </div>
                            <div className="assigneebg">
                                <span>ASSIGNEE</span>
                            </div>
                        </div>
                    </List.Item>
                    {deleteRequestList?.map((dt) => {
                        return (
                            <List.Item key={dt._id}>
                                <div className="task-grid-wrapper" style={{ gridTemplateColumns: "1fr  minmax(160px, 160px) minmax(169px, 160px)" }}>
                                    <Flex gap={10} align="center" justify="space-between">
                                        <Space>
                                            {dt.task.task_type?.length > 1 ? (
                                                <Tooltip title="Multiple Inquiries">
                                                    <Icon name="MULTI" style={{ cursor: "pointer" }} />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={INQUIRY_TYPE[dt?.task?.task_type]?.title}>
                                                    <Icon name={dt?.task?.task_type?.[0]} style={{ cursor: "pointer" }} />
                                                </Tooltip>
                                            )}
                                            <div onClick={(event) => event.stopPropagation()}>
                                                <Dropdown
                                                    menu={{
                                                        items: user?.role === "AGENT_ADMIN" ? adminDropdownItem : items,
                                                        onClick: (val: any) => handleTaskMove(val, dt.task._id),
                                                    }}
                                                    trigger={["click"]}
                                                >
                                                    <Button type="link" style={{ padding: "0px", height: "auto" }}>
                                                        <MenuOutlined style={{ lineHeight: "1" }} />
                                                    </Button>
                                                </Dropdown>
                                            </div>
                                            <Typography style={{ pointerEvents: "none", cursor: "not-allowed" }} className="list-item-title">
                                                {dt?.task?.title} {dt?.task?.is_high_prior && <img src={fireActiveIcon} />}
                                            </Typography>
                                        </Space>
                                        <div style={{ pointerEvents: "none", cursor: "not-allowed" }} className="deleteStatus">
                                            <span className={` ${statusClassName(dt.task.task_status)}`}>{dt?.task?.delete_reason?.toUpperCase()}</span>
                                        </div>
                                    </Flex>
                                    <div>
                                        <span style={{ pointerEvents: "none", cursor: "not-allowed" }}>{formateDate(dt.task.due_date)}</span>
                                    </div>
                                    <div className="assigneebg">
                                        <Flex align="center" className="assignee-cell" style={{ width: "100%" }}>
                                            <Avatar.Group
                                                max={{
                                                    count: 3,
                                                    style: { backgroundColor: "#76a3da" },
                                                    popover: { rootClassName: "AsssignnePopover" },
                                                }}
                                                size={28}
                                            >
                                                {dt.task.assign_to.map((asn: any, idx: any) => {
                                                    return (
                                                        <Tooltip title={asn.full_name} key={idx}>
                                                            <Avatar
                                                                style={{
                                                                    backgroundColor: colors[idx % colors.length],
                                                                    marginLeft: "-6px",
                                                                }}
                                                            >
                                                                {getInitials(asn.full_name)}
                                                            </Avatar>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </Avatar.Group>
                                        </Flex>
                                    </div>
                                </div>
                            </List.Item>
                        );
                    })}
                </List>
            </div>
        </>
    );
};

export default DeleteRequest;
