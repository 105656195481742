import { Checkbox, Col, Flex, Form, FormInstance, Image, Input, InputRef, Row, Space, Typography, UploadFile } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import Dragger from "antd/es/upload/Dragger";
import { InputBox, InputSelect } from "../../../components/FormInput";
import TaskStyleCss from "./style.module.scss";
import { capitalizeFirstLetter, capitalizeFirstLetterOfWord } from "../../../utils/commonFunctions";
import fireActiveIcon from "../../../assets/fireactive.svg";
import fireIcon from "../../../assets/fire.svg";
import clipBlack from "../../../assets/clipBlack.svg";
import clipSvg from "../../../assets/clip.svg";
import { InboxOutlined } from "@ant-design/icons";
import { fetchReferenceList } from "../../../slices/contactSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { useSelector } from "react-redux";

interface ICommonFormProps {
    form: FormInstance<any>;
    setIsTitleEmpty: Dispatch<SetStateAction<boolean>>;
    setIsPrior: Dispatch<SetStateAction<boolean>>;
    isPrior: boolean;
    fileList: UploadFile[];
    handleChange: (val: any) => void;
    handlePreview: (val: UploadFile) => void;
    previewImage: string;
    previewOpen: boolean;
    setPreviewOpen: Dispatch<SetStateAction<boolean>>;
    setPreviewImage: Dispatch<SetStateAction<string>>;
    setShowUpload: Dispatch<SetStateAction<string>>;
    showUpload: string;
    type: "TASK" | "INQUIRY";
}

function CommonForm({
    form,
    type,
    setIsTitleEmpty,
    setIsPrior,
    isPrior,
    fileList,
    handleChange,
    handlePreview,
    previewImage,
    previewOpen,
    setPreviewOpen,
    setPreviewImage,
    setShowUpload,
    showUpload,
}: ICommonFormProps) {
    const InputfieldRef = useRef<InputRef>(null);
    const dispatch = useDispatch<AppDispatch>();
    const { referenceList } = useSelector((state: RootState) => state.contact);
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            InputfieldRef.current?.focus();
        }
    };
    const handleClick = (type: string) => {
        setShowUpload((prevState: string) => (prevState === type ? "" : type));
    };
    const uploadButton = (
        <>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </>
    );
    useEffect(() => {
        dispatch(fetchReferenceList());
    }, []);
    return (
        <>
            <Row className="title-wrapper" justify={"space-between"}>
                <Col span={18}>
                    {type == "TASK" ? (
                        <Form.Item name="title" className="mb-0">
                            <Input
                                placeholder="Enter task name"
                                variant="borderless"
                                autoComplete="off"
                                tabIndex={0}
                                onChange={() => setIsTitleEmpty(false)}
                                onBlur={(e) => form.setFieldValue("title", capitalizeFirstLetterOfWord(e.target.value))}
                                onKeyDown={handleKeyDown}
                            />
                        </Form.Item>
                    ) : type == "INQUIRY" ? (
                        <InputSelect
                            name={"customer"}
                            options={referenceList.map((refDt) => ({
                                label: refDt.contact_name || refDt.full_name,
                                value: refDt._id,
                            }))}
                            allowClear
                            placeholder="Select Customer"
                            showSearch
                            variant="borderless"
                        />
                    ) : (
                        <div>none</div>
                    )}
                </Col>
                <Col>
                    <Form.Item className="mb-0" name={"is_prior"} valuePropName="checked">
                        <Checkbox onChange={(e) => setIsPrior(e.target.checked)} tabIndex={3}>
                            <Flex gap={8} align="center">
                                High Priority <img src={isPrior ? fireActiveIcon : fireIcon} alt="fire" />
                            </Flex>
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item className={TaskStyleCss.notes}>
                <InputBox
                    ref={InputfieldRef}
                    type="textarea"
                    name={"notes"}
                    placeholder="Add Notes here"
                    style={{ marginBottom: "10px" }}
                    autoSize={{ minRows: "7", maxRows: "22" }}
                    tabIndex={1}
                    onBlur={(e: any) => form.setFieldValue("notes", capitalizeFirstLetter(e.target.value))}
                />
            </Form.Item>
            <Space size={30} align="center" style={{ padding: "11px" }}>
                <Flex align="center" onClick={() => handleClick("FILE")}>
                    <img src={showUpload === "FILE" ? clipBlack : clipSvg} alt="clip" />
                    <Typography.Link style={{ color: showUpload === "FILE" ? "#515e68" : "#AFB5B9", fontSize: 13 }} className="ml-1">
                        File
                    </Typography.Link>
                </Flex>
                <Flex onClick={() => handleClick("CHECKLIST")}>
                    <Typography.Link
                        style={{
                            color: showUpload === "CHECKLIST" ? "#515e68" : "#AFB5B9",
                            fontSize: 13,
                            wordSpacing: "-2px",
                        }}
                    >
                        Sub Tasks
                    </Typography.Link>
                </Flex>
            </Space>
            {showUpload === "FILE" && (
                <div>
                    <div className={`${TaskStyleCss.uploadContainer} ${fileList.length > 0 ? TaskStyleCss.showPreview : ""}`}>
                        <Dragger
                            listType="picture-card"
                            fileList={fileList}
                            onChange={handleChange}
                            beforeUpload={() => false}
                            style={{ width: "100%" }}
                            onPreview={handlePreview}
                            multiple
                        >
                            {uploadButton}
                        </Dragger>
                        {previewImage && (
                            <Image
                                wrapperStyle={{ display: "none" }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(""),
                                }}
                                src={previewImage}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default CommonForm;
