import { useEffect, useState } from "react";
import { Button, Col, DatePickerProps, Divider, Flex, Form, List, Row, Spin, Typography, Upload, UploadProps } from "antd";
import { CloseOutlined, DeleteOutlined, DownloadOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { deletePassPortHistory, setIsPassportUploading, setPassPortHistory, setPassPortImage } from "../../../slices/contactSlice";
import { InputBox, InputRadio } from "../../../components/FormInput";
import FileUpload from "../../../components/FileUpload";
import uploadSvg from "../../../assets/upload-svg.svg";
import publishSvg from "../../../assets/publish.svg";
import FormCss from "./form.module.scss";
import { FiUpload } from "react-icons/fi";
import dayjs from "dayjs";
import AxiosService from "../../../utils/APIService";
import { downloadDocument } from "../../../utils/AWSService";
const _ = require("lodash");

const { Title } = Typography;

interface PassportDocPropsInterface {
    form: any;
    formId: any;
}
interface HistoryItem {
    id: string;
    name: string;
    expDate: string;
    url: string;
    file: any;
}

export const PassportDoc = ({ form, formId }: PassportDocPropsInterface) => {
    const dispatch = useDispatch<AppDispatch>();
    const { passPortImage, passPortHistory, isPassportUploading } = useSelector((state: RootState) => state.contact);
    const { user } = useSelector((state: RootState) => state.user);
    const [addPassport, setAddPassport] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<any>(null);
    const [expiryDate, setExpiryDate] = useState<any>(null);
    const [formFields, setFormFields] = useState<any[]>([]);
    const apiService = new AxiosService();

    const filePlaceholder = (
        <div className="flex flex-col items-center">
            <img className="mb-7" src={uploadSvg} style={{ width: "200px" }} alt="icon" />
            <div>
                <p style={{ color: "#1A73E8", fontSize: "14px" }}>Drag an Image here or click to upload</p>
                <span style={{ color: "#99A1BB", fontSize: "14px" }}>Upload an image in either a PNG or JPEG format. Maximum size: 2MB.</span>
            </div>
        </div>
    );

    const passportHistory: HistoryItem[] = passPortHistory[formId]?.history;

    // Function to get the correct ordinal suffix
    function getOrdinalSuffix(num: number) {
        if (num % 100 >= 11 && num % 100 <= 13) {
            return "th";
        }
        switch (num % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    const sortedPassports = passportHistory
        ?.map((passport, index) => {
            const expDateTimestamp = new Date(passport?.expDate.split("-").reverse().join("-")).getTime();
            return { ...passport, expDateTimestamp, index };
        })
        ?.sort((a, b) => b?.expDateTimestamp - a?.expDateTimestamp)
        ?.map((passport, index) => {
            let newName: string;
            if (index === 0) {
                newName = "Current Passport";
            } else {
                newName = `${index + 1}${getOrdinalSuffix(index + 1)} Last Passport`;
            }
            return { ...passport, name: newName };
        });

    useEffect(() => {
        setFormFields([
            {
                name: [formId, "passport_number"],
                label: "Passport Number",
                type: "text",
                placeholder: "Enter passport number",
            },
            {
                name: [formId, "dob"],
                label: "Date of Birth",
                type: "date",
            },
            {
                name: [formId, "gender"],
                label: "Gender",
                type: "radio",
                options: [
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                ],
            },
            {
                name: [formId, "passport_last_name"],
                label: "Last Name",
                type: "text",
                placeholder: "Enter last name",
            },
            {
                name: [formId, "passport_first_name"],
                label: "First Name",
                type: "text",
                placeholder: "Enter first name",
            },
            {
                name: [formId, "passport_middle_name"],
                label: "Middle Name",
                type: "text",
                placeholder: "Enter middle name",
            },
            {
                name: [formId, "passport_issue_date"],
                label: "Passport Issue Date",
                type: "date",
            },
            {
                name: [formId, "passport_expiry_date"],
                label: "Passport Expiry Date",
                type: "date",
            },
        ]);
    }, [formId]);

    const toggleDoc = () => {
        setAddPassport(!addPassport);
    };

    const capitalizeFirstLetter = (name: string) => {
        return name
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");
    };

    const handleImageUpload = (fileList: any, type: "front" | "back") => {
        dispatch(setPassPortImage({ formId, images: fileList, type }));
        if (fileList.length > 0) {
            const formData = new FormData();
            formData.append("image", fileList[0]?.originFileObj);

            dispatch(setIsPassportUploading({ type, isLoading: true }));

            const url = type === "front" ? `${process.env.REACT_APP_PASSPORT_OCR}/upload-passport-front` : `${process.env.REACT_APP_PASSPORT_OCR}/upload-passport-back`;

            apiService
                .post(url, formData, false, false)
                .then((res: any) => {
                    const data: any = res.Response;
                    if (_.isObject(data)) {
                        if (type === "front") {
                            const nameParts = data?.name?.split(" ");
                            form.setFieldValue([formId, "passport_number"], data?.passport_no);
                            form.setFieldValue([formId, "dob"], dayjs(data?.birth_date, "DD-MM-YYYY"));
                            const genderValue = data?.sex === "M" ? "Male" : "Female";
                            form.setFieldValue([formId, "gender"], genderValue);
                            form.setFieldValue([formId, "passport_first_name"], capitalizeFirstLetter(nameParts[0]));
                            form.setFieldValue([formId, "passport_middle_name"], capitalizeFirstLetter(nameParts[1]));
                            form.setFieldValue([formId, "passport_last_name"], capitalizeFirstLetter(data?.surname));
                            form.setFieldValue([formId, "passport_issue_date"], dayjs(data?.issue_date, "DD/MM/YYYY"));
                            form.setFieldValue([formId, "passport_expiry_date"], dayjs(data?.expiry_date, "DD-MM-YYYY"));
                        } else {
                            form.setFieldValue([formId, "passport_father_name"], capitalizeFirstLetter(data?.legal_guardian));
                            form.setFieldValue([formId, "passport_mother_name"], capitalizeFirstLetter(data?.name_of_mother));
                            form.setFieldValue([formId, "passport_spouse_name"], capitalizeFirstLetter(data?.name_of_spouse));
                        }
                    }
                })
                .catch((e: any) => console.log("error", e))
                .finally(() => dispatch(setIsPassportUploading({ type, isLoading: false })));
        }
    };

    const handleRemoveImage = (fileToRemove: File, type: "front" | "back") => {
        const updatedFiles = passPortImage[formId][type]?.filter((file: File) => file !== fileToRemove);
        dispatch(setPassPortImage({ formId, images: updatedFiles, type }));
        if (type === "front") {
            form.resetFields([
                [formId, "passport_number"],
                [formId, "dob"],
                [formId, "gender"],
                [formId, "passport_first_name"],
                [formId, "passport_middle_name"],
                [formId, "passport_last_name"],
                [formId, "passport_issue_date"],
                [formId, "passport_expiry_date"],
            ]);
        } else {
            form.resetFields([
                [formId, "passport_father_name"],
                [formId, "passport_mother_name"],
                [formId, "passport_spouse_name"],
            ]);
        }
    };

    const props: UploadProps = {
        name: "file",
        multiple: false,
        accept: ".pdf",
        listType: "picture",
        showUploadList: true,
        beforeUpload: () => false,
        onChange: (info) => {
            const { status } = info.file;
            if (status !== "uploading" && status !== "removed") {
                const file = info.fileList[info.fileList.length - 1];
                if (file.originFileObj) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const blob = new Blob([reader.result as string], { type: file.type });
                        const url = URL.createObjectURL(blob);
                        const updatedFile = {
                            ...file,
                            previewUrl: url,
                        };
                        setUploadedFile(updatedFile);
                    };
                    reader.readAsArrayBuffer(file.originFileObj);
                }
            }
        },
        onRemove: () => {
            setUploadedFile(null);
        },
        style: { backgroundColor: "white" },
        fileList: uploadedFile ? [uploadedFile] : [],
    };

    const handleUpload = () => {
        if (!uploadedFile || !expiryDate) {
            return;
        }

        const newHistoryItem: HistoryItem = {
            id: new Date().getTime().toString(),
            name: uploadedFile.name,
            expDate: dayjs(expiryDate).format("DD-MM-YYYY"),
            url: uploadedFile.previewUrl,
            file: uploadedFile,
        };

        dispatch(setPassPortHistory({ formId, newPassPort: newHistoryItem }));
        setUploadedFile(null);
        setExpiryDate(null);
    };

    const handleView = (url: string) => {
        if (url) {
            window.open(url, "_blank");
        }
    };

    const handleDelete = (id: string) => {
        dispatch(deletePassPortHistory({ formId, itemId: id }));
    };

    const onDateChange: DatePickerProps["onChange"] = (date) => {
        setExpiryDate(date);
    };
    const fileDownload = (data: any, filename: string) => {
        if (_.has(data, "originFileObj")) {
            const link = document.createElement("a");
            link.href = data.thumbUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else if (_.has(data, "file_name")) {
            downloadDocument(`passenger_doc/${user?.tenant_id}/${formId}/${data.file_name}`, data.doc_type, filename);
        }
    };

    return (
        <>
            <div className="pr-5 pl-5 pb-5 mt-5">
                <Title level={4} className={`${FormCss.titleLevel4}`}>
                    Passport Front Details
                </Title>
                <Spin spinning={isPassportUploading.front} tip="Uploading..." size="large">
                    <Row gutter={[15, 15]}>
                        <Col span={24}>
                            <Form.Item className="passport-dropbox mb-1" name={[formId, "passportFront"]}>
                                <FileUpload
                                    onRemove={(file: any) => handleRemoveImage(file, "front")}
                                    required
                                    placeholder={filePlaceholder}
                                    setFile={(val: any) => handleImageUpload(val, "front")}
                                    fileData={passPortImage[formId]?.front ?? []}
                                />
                                {_.get(passPortImage, `[${formId}].front[0]`) && (
                                    <Flex justify="end" style={{ marginTop: 10 }}>
                                        <Button type="text" onClick={() => fileDownload(_.get(passPortImage, `[${formId}].front[0]`), "Front_Image")}>
                                            Download <DownloadOutlined />
                                        </Button>
                                    </Flex>
                                )}
                            </Form.Item>
                        </Col>
                        {formFields?.map((fld: any, index: number) => (
                            <Col key={index} span={8}>
                                {fld.type === "radio" ? (
                                    <Form.Item name={[formId, "gender"]}>
                                        <InputRadio className="mb-0" {...fld} />
                                    </Form.Item>
                                ) : (
                                    <InputBox className="mb-0" {...fld} />
                                )}
                            </Col>
                        ))}
                    </Row>
                </Spin>
            </div>
            <Divider
                style={{
                    borderBlockStart: "1px solid rgba(219, 223, 233, 1)",
                    margin: 0,
                }}
            />
            <div className="p-5">
                <Title level={4} className={`${FormCss.titleLevel4}`}>
                    Passport Back Details
                </Title>
                <Spin spinning={isPassportUploading.back} tip="Uploading..." size="large">
                    <Row gutter={[15, 15]}>
                        <Col span={24}>
                            <Form.Item className="mb-1 passport-dropbox" name={[formId, "passportBack"]}>
                                <FileUpload
                                    onRemove={(file: any) => handleRemoveImage(file, "back")}
                                    required
                                    placeholder={filePlaceholder}
                                    setFile={(val: any) => handleImageUpload(val, "back")}
                                    fileData={passPortImage[formId]?.back ?? []}
                                />
                                {_.get(passPortImage, `[${formId}].back[0]`) && (
                                    <Flex justify="end" style={{ marginTop: 10 }}>
                                        <Button type="text" onClick={() => fileDownload(_.get(passPortImage, `[${formId}].back[0]`), "Back_Image")}>
                                            Download <DownloadOutlined />
                                        </Button>
                                    </Flex>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <InputBox label="Father Full Name" className="mb-0" placeholder="Enter father full name" name={[formId, "passport_father_name"]} />
                        </Col>
                        <Col span={24}>
                            <InputBox label="Mother Full Name" className="mb-0" placeholder="Enter mother full name" name={[formId, "passport_mother_name"]} />
                        </Col>
                        <Col span={24}>
                            <InputBox label="Spouse Name" className="mb-0" placeholder="Enter spouse" name={[formId, "passport_spouse_name"]} />
                        </Col>
                    </Row>
                </Spin>
            </div>
            <Divider
                style={{
                    borderBlockStart: "1px solid rgba(219, 223, 233, 1)",
                    margin: 0,
                }}
            />
            <div className="p-5">
                <Title level={4} className={`${FormCss.titleLevel4}`}>
                    Full Passport
                </Title>
                <Button onClick={toggleDoc} type="primary" className={`${FormCss.addButton} mb-1`} block icon={<PlusOutlined />}>
                    Add Passport
                </Button>
                {addPassport && (
                    <div className={`${FormCss.uploadCard} mb-2`}>
                        <Flex justify="space-between" align="center" className="card-header">
                            <Title className="card-title" level={2}>
                                Upload
                            </Title>
                            <Button onClick={toggleDoc} type="link" style={{ color: "inherit" }}>
                                <CloseOutlined style={{ fontSize: "15px" }} />
                            </Button>
                        </Flex>
                        <div className="card-body">
                            <div className="space-wrapper">
                                <div className="passport-dropbox-md">
                                    <Row gutter={[0, 15]}>
                                        <Col span={24}>
                                            <Upload.Dragger {...props}>
                                                <p className="ant-upload-drag-icon">
                                                    <FiUpload size={40} color="#99A1BB" />
                                                </p>
                                                <p className="ant-upload-text" style={{ color: "#1A73E8" }}>
                                                    Click or drag file to this area to upload
                                                </p>
                                                <p className="ant-upload-hint" style={{ color: "#99A1BB" }}>
                                                    Upload a PDF format. Maximum size: 2MB.
                                                </p>
                                            </Upload.Dragger>
                                        </Col>
                                        <Col span={24}>
                                            <InputBox type="date" label="Expiry Date" value={expiryDate} onChange={onDateChange} className="mb-0" />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <Flex justify="flex-end">
                                <Button className={FormCss.formButton} type="primary" onClick={handleUpload} disabled={!!!expiryDate || !!!uploadedFile}>
                                    Upload
                                </Button>
                            </Flex>
                        </div>
                    </div>
                )}
                {passPortHistory[formId]?.history && (
                    <div className={`${FormCss.uploadCard}`}>
                        <Flex justify="space-between" align="center" className="card-header">
                            <Title level={3} style={{ marginBottom: 0 }} className={FormCss.titleLevel3}>
                                Uploaded Documents
                            </Title>
                        </Flex>
                        <div className="card-body">
                            <List
                                size="small"
                                className={FormCss.docList}
                                header={
                                    <div className="doc-list-header">
                                        <div>Name</div>
                                        <div>Expiry Date</div>
                                        <div>Action</div>
                                    </div>
                                }
                                dataSource={sortedPassports}
                                renderItem={(item: any) => (
                                    <List.Item className="doc-list-item" key={item?.id}>
                                        <div>{item?.name}</div>
                                        <div>{item?.expDate}</div>
                                        <div>
                                            <Flex gap={16}>
                                                <Button style={{ color: "rgba(153, 161, 187, 1)" }} type="link" icon={<EyeOutlined />} onClick={() => handleView(item.url)} />
                                                <Button style={{ color: "rgba(153, 161, 187, 1)" }} type="link" icon={<DeleteOutlined />} onClick={() => handleDelete(item.id)} />
                                            </Flex>
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default PassportDoc;
