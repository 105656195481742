import { useEffect, useRef, useState } from "react";
import {
    Alert,
    message,
    Button,
    Card,
    Drawer,
    Flex,
    Form,
    GetProp,
    Typography,
    UploadFile,
    UploadProps,
    InputRef,
} from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { DRAWER_TYPE } from "../../../utils/const";
import AxiosService from "../../../utils/APIService";
import { getBase64 } from "../../../utils/commonFunctions";
import CheckList from "./CheckList";
import { useInquiry } from "..";
import { setDrawerOpen } from "../../../slices/layoutSlice";
import TaskStyleCss from "./style.module.scss";
import Assignee from "./Assignee";
import DueDate from "./DueDate";
import DrawerHeader from "./DrawerHeader";
import CommonForm from "./CommonForm";

export interface subTaskData {
    id?: string;
    is_completed: boolean;
    title: string;
    due_date?: string;
}
export interface subTaskDataListInterface {
    name: string;
    task: subTaskData[];
}
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

function CreateTask() {
    const { taskDrawerOpen } = useSelector((state: RootState) => state.layout);
    const { user } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();
    const apiService = new AxiosService();
    const { assignList }: any = useInquiry();
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const [isTitleEmpty, setIsTitleEmpty] = useState(false);
    const [isPrior, setIsPrior] = useState(false);
    const [taskForm] = Form.useForm();
    const [showUpload, setShowUpload] = useState("");
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [subTaskData, setSubTaskData] = useState<subTaskDataListInterface[]>([
        {
            name: "SubTask List",
            task: [],
        },
    ]);
    const [addTaskLoader, setAddTaskLoader] = useState(false);
    const [addAnotherTaskLoader, setAddAnotherTaskLoader] = useState(false);
    const [loadingState, setLoadingState] = useState("");

    const [selectedAssignee, setSelectedAssignee] = useState([]);

    useEffect(() => {
        taskForm.setFieldValue("dueDate", dayjs());
    }, [taskForm, user?._id, taskDrawerOpen]);

    const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const resetForm = () => {
        taskForm.resetFields();
        setFileList([]);
        setSubTaskData([{ name: "SubTask List", task: [] }]);
        setIsTitleEmpty(false);
        setIsPrior(false);
        setShowUpload("");
        setSelectedAssignee([]);
        setLoadingState("");
    };

    const drawerCloseHandle = () => {
        resetForm();
        dispatch(setDrawerOpen(null));
    };

    const createInquiry = async (val: any, shouldCloseDrawer: boolean) => {
        const formdata = new FormData();
        formdata.append("type", JSON.stringify(val.type));
        formdata.append("title", val.title);
        formdata.append("dueDate", val.dueDate.format("DD-MM-YYYY HH:mm"));
        if (val.notes) formdata.append("notes", val.notes);
        formdata.append("is_prior", val.is_prior);

        if (selectedAssignee?.length > 0) {
            formdata.append("assignTo", JSON.stringify(selectedAssignee.map((asn: any) => asn._id)));
        }

        if (subTaskData?.length > 0) {
            formdata.append("subtask", JSON.stringify(subTaskData));
        }

        if (fileList?.length > 0) {
            fileList.forEach((file: any, i: number) => {
                formdata.append(`doc_${i + 1}`, file.originFileObj);
            });
        }

        let hasError = false;

        if (!val.type) {
            message.error("Task type is required");
            hasError = true;
        }

        if (!val.title) {
            setIsTitleEmpty(true);
            hasError = true;
        }

        if (!hasError) {
            setLoadingState(shouldCloseDrawer ? "addTask" : "addAnotherTask");

            try {
                await apiService.post("/task/create", formdata);
                if (shouldCloseDrawer) {
                    drawerCloseHandle();
                } else {
                    resetForm();
                }
            } catch (e) {
                console.error("Error creating task:", e);
            } finally {
                setLoadingState("");
            }
        }
    };

    const handleFormSubmit = (shouldCloseDrawer: boolean) => {
        taskForm
            .validateFields()
            .then((val) => createInquiry(val, shouldCloseDrawer))
            .catch(() => {
                console.log("Form validation failed");
            });
    };

    const handleSubtaskChange = (val: any, index: number) => {
        const taskList = [...subTaskData];
        taskList[index] = val;
        setSubTaskData(taskList);
    };

    const addSubTaskList = () => {
        setSubTaskData([
            ...subTaskData,
            {
                name: "SubTask List",
                task: [],
            },
        ]);
    };

    const removeSubTaskList = (index: number) => {
        const updatedSubTaskData = subTaskData.filter((_, index) => index !== index);
        setSubTaskData(updatedSubTaskData);
    };

    const getAssigneeList = (val: any) => {
        const dataList = assignList.filter((asl: any) => val.includes(asl._id)).map((asl: any) => ({ name: asl.full_name, _id: asl._id }));
        setSelectedAssignee(dataList);
    };

    const handleCloseTag = (id: any) => {
        setSelectedAssignee((prev) => prev.filter((dt: any) => dt._id !== id));
    };
    
    return (
        <>
            <Drawer
                onClose={drawerCloseHandle}
                open={taskDrawerOpen === DRAWER_TYPE.CREATE_TASK}
                width={"calc(100% - 233px)"}
                className={TaskStyleCss.taskDrawer}
                styles={{
                    header: {
                        display: "none",
                    },
                }}
                footer={[
                    <div className="CreateTaskFooter">
                        <Flex gap={20}>
                            <Button className="addTaskbtn" onClick={() => handleFormSubmit(true)} loading={loadingState === "addTask"}>
                                ADD TASK
                            </Button>
                            <Button className="createAnotherTaskbtn" onClick={() => handleFormSubmit(false)} loading={loadingState === "addAnotherTask"}>
                                ADD TASK AND CREATE ANOTHER ONE
                            </Button>
                            <Button className="cancalbtn" onClick={drawerCloseHandle} disabled={loadingState !== ""}>
                                Cancel
                            </Button>
                        </Flex>
                    </div>,
                ]}
                style={{ boxShadow: "0px 0px 5px white" }}
            >
                <div className="close-bar" style={{ top: 18 }}>
                    <Flex onClick={drawerCloseHandle} className="close-label" align="center">
                        <CloseOutlined style={{ fontSize: "12px" }} />
                        <span>TASK</span>
                    </Flex>
                </div>

                <Form
                    name="task"
                    // onFinish={(val) => createInquiry(val)}
                    layout="vertical"
                    form={taskForm}
                    initialValues={{
                        dueDate: dayjs(),
                    }}
                >
                    {<DrawerHeader />}
                    {isTitleEmpty && <Alert type="error" message="The task name is not specified." banner style={{ marginBottom: "5px" }} />}
                    <Card className="createTaskWrapper">
                        <CommonForm
                            fileList={fileList}
                            form={taskForm}
                            handleChange={handleChange}
                            handlePreview={handlePreview}
                            isPrior={isPrior}
                            previewImage={previewImage}
                            previewOpen={previewOpen}
                            setIsPrior={setIsPrior}
                            setIsTitleEmpty={setIsTitleEmpty}
                            setPreviewImage={setPreviewImage}
                            setPreviewOpen={setPreviewOpen}
                            setShowUpload={setShowUpload}
                            showUpload={showUpload}
                            type="TASK"
                        />
                        {showUpload === "CHECKLIST" && (
                            <div className={TaskStyleCss.checkList}>
                                {subTaskData.map((st: subTaskDataListInterface, index: number) => (
                                    <CheckList data={st} taskListChange={(val: any) => handleSubtaskChange(val, index)} removeSubTaskList={() => removeSubTaskList(index)} />
                                ))}
                                <Button
                                    style={{ color: "#80868e", fontSize: "14px", paddingLeft: 0, marginBottom: "13px" }}
                                    size="large"
                                    type="link"
                                    onClick={addSubTaskList}
                                    icon={<PlusOutlined style={{ fontSize: "11px", lineHeight: "1", fill: "#80868e" }} />}
                                >
                                    Add SubTask List
                                </Button>
                            </div>
                        )}
                        <div className="taskField">
                            <DueDate form={taskForm} />
                            {user?.role === "AGENT_ADMIN" && (
                                <Assignee assignList={assignList} getAssigneeList={getAssigneeList} handleCloseTag={handleCloseTag} selectedAssignee={selectedAssignee} />
                            )}
                        </div>
                    </Card>
                </Form>
            </Drawer>
        </>
    );
}

export default CreateTask;
