import { useCallback, useEffect, useMemo, useState } from "react";
import { MenuOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Flex, Input, Layout, MenuProps, Typography } from "antd";
import { LocalStorageService } from "../../../utils/LocalStorage";
import { useNavigate } from "react-router-dom";
import downArrow from "../../../assets/down-arrow.svg";
import headerCss from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { setSidebarToggle } from "../../../slices/layoutSlice";
import { isLogin, setProfileData, setUserData } from "../../../slices/userSlice";

function HeaderLayout() {
    const dispatch = useDispatch<AppDispatch>();
    const { sidebarOpen } = useSelector((state: RootState) => state.layout);
    const { user } = useSelector((state: RootState) => state.user);
    const [date, setDate] = useState(new Date());
    const navigate = useNavigate();
    const menuItems: MenuProps["items"] = [
        {
            key: "1",
            label: "Logout",
        },
    ];

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);

        return () => {
            clearInterval(timerID);
        };
    }, []);
    const tick = useCallback(() => {
        setDate(new Date());
    }, []);
    const options: any = { hour: "2-digit", minute: "2-digit", hour12: false };
    const timeString = useMemo(() => date.toLocaleTimeString("en-GB", options), [date]);
    const menuItemSelect = (val: any) => {
        switch (val.key) {
            case "1":
                LocalStorageService.removeItem("token");
                LocalStorageService.removeItem("user");
                dispatch(isLogin(false));
                dispatch(setProfileData(null));
                dispatch(setUserData(null));
                navigate("/login");
                break;
        }
    };
    return (
        <Layout.Header className={headerCss.customHeader}>
            <div className={headerCss.container}>
                <Flex justify="space-between" align="center">
                    <Flex style={{ width: "100%" }} align="center">
                        <Flex align="center" style={{ width: "44.5%", marginRight: "1.8%" }}>
                            <Button icon={<MenuOutlined style={{ fontSize: 22 }} />} type="text" onClick={() => dispatch(setSidebarToggle(!sidebarOpen))} />
                            <Input.Search
                                styles={{
                                    affixWrapper: {
                                        backgroundColor: "#F5F6FA1A",
                                        border: "none",
                                        borderRadius: 50,
                                        color: "#fff",
                                        padding: "7px 16px",
                                    },
                                    input: {
                                        paddingLeft: 6,
                                    },
                                }}
                                prefix={<SearchOutlined />}
                                placeholder="Search"
                                className={headerCss.customSearch}
                                size="large"
                            />
                        </Flex>
                    </Flex>
                    <Typography.Text className={headerCss.clockText}>{timeString}</Typography.Text>
                    <Dropdown menu={{ items: menuItems, onClick: menuItemSelect }} trigger={["click"]}>
                        <div className={headerCss.profileLink}>
                            <Flex align="center">
                                <Avatar size={36} icon={<UserOutlined />} style={{ marginRight: 8, background: "rgb(255 255 255 / 32%)" }} />
                                <div className="profile-dropdown">
                                    <Flex align="center">
                                        <Typography.Text className="user-name">{user?.full_name}</Typography.Text>
                                        <img src={downArrow} alt="icon"/>
                                    </Flex>
                                </div>
                            </Flex>
                        </div>
                    </Dropdown>
                </Flex>
            </div>
        </Layout.Header>
    );
}

export default HeaderLayout;
