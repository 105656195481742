import { Checkbox, Form, Select } from "antd";
import React, { useEffect, useState } from "react";

export interface optionInterface {
    value: string;
    label: any;
}

export interface InputDropdownInterface {
    name?: any;
    options: optionInterface[];
    handleChange?: (val: any) => void;
    placeholder?: string;
    label?: string;
    allowClear?: boolean;
    showSearch?: boolean;
    rules?: any;
    mode?: "multiple" | "tags" | undefined;
    style?: any;
    defaultValue?: any;
    onBlur?: any;
    withCheckboxes?: boolean;
    defaultOpen?: boolean;
    onChangeHandle?: (val: any) => void;
    variant?: "outlined" | "borderless" | "filled" | undefined
}

const InputSelect: React.FC<InputDropdownInterface> = ({
    options,
    handleChange,
    placeholder,
    label,
    allowClear,
    showSearch,
    mode,
    defaultValue,
    withCheckboxes,
    onBlur,
    style,
    defaultOpen,
    onChangeHandle,
    variant,
    ...rest
}) => {
    const [selectedItems, setSelectedItems] = useState<string[]>(defaultValue || []);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(defaultOpen || false);

    useEffect(() => {
        setSelectedItems(defaultValue || []);
    }, [defaultValue, options]);

    const handleCheckboxChange = (checked: boolean, value: string) => {
        const newSelectedItems = checked ? [...selectedItems, value] : selectedItems.filter((item) => item !== value);
        setSelectedItems(newSelectedItems);
        handleChange?.(newSelectedItems);
    };

    const handleLabelClick = (val: any) => {
        const updatedSelectedNames = [...selectedItems];
        const index = updatedSelectedNames.indexOf(val);
        if (index !== -1) {
            updatedSelectedNames.splice(index, 1);
        } else {
            updatedSelectedNames.push(val);
        }
        setSelectedItems(updatedSelectedNames);
        handleChange?.(updatedSelectedNames);
    };

    return (
        <Form.Item label={label} {...rest}>
            <Select
                variant={variant}
                mode={mode}
                allowClear={allowClear}
                size="large"
                style={style}
                placeholder={placeholder}
                onBlur={onBlur}
                showSearch={showSearch}
                filterOption={(input: any, option: any) => {
                    return option?.searchKey?.toLowerCase().includes(input.toLowerCase()) || option?.value?.toLowerCase().includes(input.toLowerCase());
                }}
                value={selectedItems}
                onChange={(values) => {
                    setSelectedItems(values);
                    handleChange?.(values);
                    if (onChangeHandle) {
                        onChangeHandle(values);
                    }
                }}
                open={dropdownOpen}
                onDropdownVisibleChange={setDropdownOpen}
                options={options.map((option) => ({
                    label: withCheckboxes ? (
                        <div>
                            <Checkbox checked={selectedItems.includes(option.value)} onChange={(e) => handleCheckboxChange(e.target.checked, option.value)}>
                                <label onClick={() => handleLabelClick(option.value)}>{option.label}</label>
                            </Checkbox>
                        </div>
                    ) : (
                        option.label
                    ),
                    value: option.value,
                    searchKey: option.label,
                }))}
            />
        </Form.Item>
    );
};

export default InputSelect;
