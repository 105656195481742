import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import notesSlice from "./notesSlice";
import layoutSlice from "./layoutSlice";
import taskSlice from "./taskSlice";
import apiServiceSlice from "./apiServiceSlice";
import contactSlice from "./contactSlice";
import commonDataSlice from "./commonDataSlice";
import minimizeSlice from "./minimizeSlice";

const rootReducer = combineReducers({
    user: userSlice,
    notes: notesSlice,
    layout: layoutSlice,
    task: taskSlice,
    apiService: apiServiceSlice,
    contact: contactSlice,
    commonData: commonDataSlice,
    minimize: minimizeSlice
});

export default rootReducer;
