// axiosInstance.ts

import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { LocalStorageService } from "./LocalStorage";
import store from "../store";
import { isLogin, setProfileData, setUserData } from "../slices/userSlice";

// Create the axios instance
const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Adjust the base URL as needed
    // timeout: 10000, // Adjust the timeout value
    headers: {
        // 'Content-Type': 'application/json',
    },
});

// Add a request interceptor for token management
axiosInstance.interceptors.request.use(
    (config) => {
        const token = LocalStorageService.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`; // Attach token if available
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor for global error handling
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        if (error.response?.status === 401) {
            // Handle 401 Unauthorized globally
            LocalStorageService.removeItem("token");
            LocalStorageService.removeItem("user");
            store.dispatch(isLogin(false));
            store.dispatch(setProfileData(null));
            store.dispatch(setUserData(null));
            // Optional: Redirect to login or show a message
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
