import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";

export const fetchComments = createAsyncThunk("task/fetchCommentsById ", async (taskId: string, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`/task/comment/${taskId}`);
        return response?.data?.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || error.message);
    }
});

// Define the interface for the task item
export interface MinList {
    name: string;
    _id: string;
    type: "TASK" | "CONTACT";
}

interface User {
    _id: string;
    full_name: string;
}

export interface IComment {
    _id: string;
    task: string;
    user: User;
    message: string;
    mentions: string[];
    replies: string[];
    reactions: string[];
    created_on: string;
    __v: number;
}

// Define the interface for the state
export interface TaskState {
    minimizeList: MinList[];
    openTaskId: string;
    comments: IComment[];
}

// Initial state
const initialState: TaskState = {
    minimizeList: [],
    openTaskId: "",
    comments: [],
};

const taskSlice = createSlice({
    name: "task",
    initialState,
    reducers: {
        setMinimizeItem(state, action: PayloadAction<MinList>) {
            if (!state.minimizeList.find((tl) => tl._id === action.payload._id)) {
                state.minimizeList.push(action.payload);
            }
        },
        closeMinimizeTask(state, action: PayloadAction<string>) {
            state.minimizeList = state.minimizeList.filter((tsk) => tsk._id !== action.payload);
        },
        openTask(state, action: PayloadAction<string>) {
            state.openTaskId = action.payload;
        },
        closeTask(state) {
            state.openTaskId = "";
        },
        addComment(state, action: PayloadAction<{ taskId: string; commentData: IComment }>) {
            state.comments.push(action.payload.commentData);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchComments.fulfilled, (state, action) => {
            state.comments = action.payload;
        });
    },
});

export const { setMinimizeItem, closeMinimizeTask, openTask, closeTask, addComment } = taskSlice.actions;

export default taskSlice.reducer;
