import { useEffect, useState } from "react";
import { Button, Col, Collapse, Divider, Image, Row, Typography, Upload, UploadProps } from "antd";
import { cities, country, getStateByCity, stateList } from "../../../utils/data";
import { useDispatch, useSelector } from "react-redux";
import { setMobileNumber, setProfileImage, setReferenceBy } from "../../../slices/contactSlice";
import { AppDispatch, RootState } from "../../../store";
import { InputBox, InputSelect } from "../../../components/FormInput";
import ContactInput from "../../../components/FormInput/contactInput";
import FormDocument from "./FormDocument";
import pencilSVG from "../../../assets/pencil.svg";
import personSVG from "../../../assets/personAvtar.svg";
import FormCss from "./form.module.scss";
import { PlusOutlined } from "@ant-design/icons";

const state = stateList();
const { Title } = Typography;
const { Panel } = Collapse;

interface ContactFormPropsInterface {
    formId: string;
    form: any;
}

const ContactForm = ({ formId, form }: ContactFormPropsInterface) => {
    const dispatch = useDispatch<AppDispatch>();
    const { profileImage, referenceList, mobileNumber, referenceBy } = useSelector((state: RootState) => state.contact);
    const [previewImage, setPreviewImage] = useState<string | undefined>(personSVG);
    const [nameUpdated, setNameUpdated] = useState(false);
    const [isUploadCollapse, setIsUploadCollapse] = useState(false);

    const updateField = (fieldName: string, value: any) => {
        form.setFieldValue([formId, fieldName], value);
    };

    const handleContactNameChange = (e: any) => {
        const value = e.target.value;
        if (value === "") {
            setNameUpdated(false);
        }
        updateField("contact_name", value);
        if (!nameUpdated) {
            updateField("full_name", value);
        }
    };

    const handleFullNameChange = (e: any) => {
        const value = e.target.value;
        updateField("full_name", value);

        if (!form.getFieldValue([formId, "contact_name"])) {
            updateField("contact_name", value);
        }
        setNameUpdated(true);
    };

    const selectStateBaseOnCity = (val: any) => {
        form.setFieldValue([formId, "state"], getStateByCity(val));
    };

    useEffect(() => {
        form.setFieldValue([formId, "country"], "IN");
        if (profileImage[formId]?.url) {
            setPreviewImage(profileImage[formId]?.url);
        }
        const fullName = form.getFieldValue([formId, "full_name"]);
        if (fullName) {
            setNameUpdated(true);
        }
        const referenceByValue = form.getFieldValue([formId, "reference_by"]);
        if (referenceBy && !referenceByValue) {
            form.setFieldValue([formId, "reference_by"], referenceBy);
        }
        const contactNumber = form.getFieldValue([formId, "contact_number"]);
        if (mobileNumber && !contactNumber) {
            form.setFieldValue([formId, "contact_number"], mobileNumber);
        }
    }, []);

    const getBase64 = (file: File, callback: (imageUrl: string) => void) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => callback(reader.result as string);
    };

    const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
        dispatch(setProfileImage({ formId, images: newFileList }));
        if (newFileList.length > 0) {
            getBase64(newFileList[0].originFileObj as File, (imageUrl: string) => {
                setPreviewImage(imageUrl);
            });
        } else {
            setPreviewImage(personSVG);
        }
    };

    const onChange = () => {
        setIsUploadCollapse(!isUploadCollapse);
    };

    return (
        <div className={FormCss.FormComponent}>
            <div className="pt-5 pr-5 pl-5">
                <Row gutter={[20, 0]}>
                    <Col span={4}>
                        <div className={FormCss.avatar}>
                            <Image src={previewImage} preview={false} alt="Profile Image" />
                            <Upload maxCount={1} className="upload-button" fileList={[]} onChange={handleChange} accept=".png, .jpeg, .jpg" beforeUpload={() => false}>
                                <div>
                                    <Image src={pencilSVG} preview={false} alt="Upload" />
                                </div>
                            </Upload>
                        </div>
                    </Col>
                    <Col span={20}>
                        <InputBox
                            className="input-field"
                            name={[formId, "contact_name"]}
                            label="Save contact name as"
                            placeholder="Enter name"
                            onChange={handleContactNameChange}
                            rules={[{ required: true }]}
                        />
                    </Col>

                    <Col span={12}>
                        <InputBox
                            className="input-field"
                            name={[formId, "full_name"]}
                            label="Name (First & Last Name)"
                            placeholder="Enter name"
                            onChange={handleFullNameChange}
                            rules={[{ required: true }]}
                        />
                    </Col>

                    <Col span={12}>
                        <InputBox rules={[{ type: "email" }]} className="input-field" name={[formId, "email"]} label="Email" placeholder="Enter Email" />
                    </Col>
                    <Col span={12}>
                        <ContactInput
                            form={form}
                            name={[formId, "contact_number"]}
                            label="Contact Number"
                            onChange={(value) => {
                                dispatch(setMobileNumber(value));
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        {/* <InputBox className="input-field" name={[formId, "reference_by"]} label="Reference By" placeholder="Enter name" /> */}
                        <InputSelect
                            name={[formId, "reference_by"]}
                            options={referenceList?.map((refDt) => ({
                                label: refDt.contact_name || refDt.full_name,
                                value: refDt._id,
                            }))}
                            allowClear
                            label="Reference By"
                            placeholder="Select Reference"
                            showSearch
                            onChangeHandle={(value) => dispatch(setReferenceBy(value))}
                        />
                    </Col>
                    <Col span={24}>
                        <InputBox className="input-field" name={[formId, "residents_address"]} label="Current Residents Address" placeholder="Enter address" />
                    </Col>
                    <Col span={12}>
                        <InputSelect
                            name={[formId, "city"]}
                            options={cities.map((ct: any) => ({
                                label: ct.name,
                                value: ct.name,
                            }))}
                            label="City"
                            placeholder="Select City"
                            showSearch
                            onChangeHandle={selectStateBaseOnCity}
                        />
                    </Col>
                    <Col span={12}>
                        <InputBox className="input-field" name={[formId, "pincode"]} label="Pin Code" placeholder="Enter Pin Code" />
                    </Col>
                    <Col span={12}>
                        <InputSelect
                            name={[formId, "state"]}
                            options={state.map((st: string) => ({
                                label: st,
                                value: st,
                            }))}
                            label="State"
                            placeholder="Select State"
                            showSearch
                        />
                    </Col>
                    <Col span={12}>
                        <InputSelect
                            name={[formId, "country"]}
                            options={country.map((ct) => ({
                                label: ct.name,
                                value: ct.code,
                            }))}
                            label="Country"
                            placeholder="Select Country"
                            showSearch
                        />
                    </Col>
                </Row>
            </div>
            <Divider
                style={{
                    borderBlockStart: "1px solid rgba(219, 223, 233, 1)",
                    margin: 0,
                }}
            />

            <Collapse ghost bordered={false} style={{ padding: 0 }}>
                <Panel
                    showArrow={false}
                    header={
                        <Button onClick={onChange} type="primary" className={`${FormCss.addButton}`} block icon={<PlusOutlined />}>
                            Upload Document
                        </Button>
                    }
                    key="1"
                >
                    <FormDocument form={form} formId={formId} />
                </Panel>
            </Collapse>
        </div>
    );
};

export default ContactForm;
