import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LayoutState {
    sidebarOpen: boolean;
    taskDrawerOpen: "CREATE_TASK" | "TASK_DETAIL" | "CREATE_CONTACT" | null;
    contactDrawerOpen: "CREATE_CONTACT" | null;
    contactId: string;
}

const initialState: LayoutState = {
    sidebarOpen: false,
    taskDrawerOpen: null,
    contactDrawerOpen: null,
    contactId: ""
};

const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setSidebarToggle(state, action: PayloadAction<boolean>) {
            state.sidebarOpen = action.payload;
        },
        setDrawerOpen(state, action: PayloadAction<"CREATE_TASK" | "TASK_DETAIL" | "CREATE_CONTACT" | null>) {
            state.taskDrawerOpen = action.payload;
        },
        setContactDrawerOpen(state, action: PayloadAction<"CREATE_CONTACT" | null>) {
            state.contactDrawerOpen = action.payload;
        },
        setContactOpenId(state, action: PayloadAction<string>) {
            state.contactId = action.payload;
        },
    },
});

export const { setSidebarToggle, setDrawerOpen, setContactDrawerOpen, setContactOpenId } = layoutSlice.actions;

export default layoutSlice.reducer;
