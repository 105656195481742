import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectRoute from "./security/ProtectRoute";
import { LocalStorageService } from "./utils/LocalStorage";
import { UserInterface } from "./slices/userSlice";
import Inquiry from "./pages/Inquiry";
import ContactList from "./pages/ContactList";
import UserLayout from "./components/UserLayout";
import Notes from "./pages/Notes";
import Login from "./pages/Login";

function AppRoutes() {
    const userData: UserInterface = LocalStorageService.getItem("user");
    const token = LocalStorageService.getItem("token");

    const routerPath = userData && token ? "/inquiry" : "/login";
    const routeList = [
        {
            path: "/login",
            component: Login,
            isAuthenticated: false,
        },
        {
            path: "/inquiry",
            component: Inquiry,
            layout: UserLayout,
            isAuthenticated: true,
        },
        {
            path: "/notes",
            component: Notes,
            layout: UserLayout,
            isAuthenticated: true,
        },
        {
            path: "/contacts",
            component: ContactList,
            layout: UserLayout,
            isAuthenticated: true,
        },
    ];

    return (
            <Routes>
                {routeList
                    .map((route) => route.isAuthenticated ? (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <ProtectRoute
                                    component={route.component}
                                    layout={route.layout}
                                />
                            }
                        />
                    ) : (<Route key={route.path} path={route.path} Component={route.component} />))}
    
                <Route path="/" element={<Navigate to={routerPath} />} />
            </Routes>
    );
}

export default AppRoutes;
