import { SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Flex, Input, Space, Typography } from "antd";
import React from "react";
import commonCss from "../css/commonStyle.module.scss";

interface SearchHeaderPropsInterface {
    children: any;
    title: String;
    searchValue?: string;
    onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const flash = (
    <svg width="15" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.07681 0.115356H11.5383L5.99989 5.65382H10.6153L2.30758 14.8846L5.07681 7.49997H0.461426L5.07681 0.115356Z" fill="white" />
    </svg>
);
function SearchHeader({ children, title, searchValue, onSearchChange }: SearchHeaderPropsInterface) {
    return (
        <Flex justify="space-between" align="center" gap={30} style={{ marginBottom: 10 }} className={commonCss.mytaskBlock}>
            <Typography.Title level={3} className={commonCss.pageTitle}>
                {title}
            </Typography.Title>
            <Space size={15} style={{ width: "100%" }} className={commonCss.customFilterBar}>
                {children}
                <Input
                    placeholder="Filter and Search"
                    value={searchValue}
                    onChange={onSearchChange}
                    addonAfter={<SearchOutlined style={{ color: "#6F7994" }} />}
                    className={commonCss.searchInput}
                />
                <Button className={commonCss.defaultBtn} type="default">
                    <SettingOutlined style={{ fontSize: 16, color: "#fff" }} />
                </Button>
                <Button className={commonCss.defaultBtn} type="default">
                    {flash}
                </Button>
            </Space>
        </Flex>
    );
}

export default SearchHeader;
