import { useEffect, useState } from "react"
import AxiosService from "../../utils/APIService"
import { Table } from "antd"
import dayjs from "dayjs"

interface HistoryPropsInterface {
    id: string
}
const HistoryBlock = ({ id }: HistoryPropsInterface) => {
    const [history, setHistoryList] = useState([])
    const apiService = new AxiosService()
    const tabelCol = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Created By',
            dataIndex: 'create_by',
            key: 'create_by',
        },
        {
            title: 'Update Disposition',
            dataIndex: 'update_desc',
            key: 'update_desc',
        },
        {
            title: 'Update',
            dataIndex: 'update',
            key: 'update',
        },

    ]
    useEffect(() => {
        if (id) {
            fetchHistory(id)
        }
    }, [id])

    const fetchHistory = (taskId: string) => {
        apiService.get(`/task/history/${taskId}`)
            .then((res:any) => {
                const data = res.data.map((dt:any) => ({
                    key: dt._id,
                    date: dayjs(dt.created_at).format("DD-MM-YYYY HH:mm"),
                    create_by: dt?.created_by?.full_name,
                    update_desc: dt?.update_description,
                    update: dt?.update,
                }))
                setHistoryList(data)
            })
            .catch(e => {
                console.log(e);
            })
    }

    return <>
        <div style={{ padding: 11, paddingBottom: 0 }}>
                <Table columns={tabelCol} dataSource={history} pagination={false} />
            </div>
        </>
}

export default HistoryBlock