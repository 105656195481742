//  sideBar
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Flex, Image, Menu, MenuProps, Typography } from "antd";
import { RootState } from "../../../store";
import Sider from "antd/es/layout/Sider";
import logo from "../../../assets/logo.svg";
import SiderCss from "./style.module.scss";
import dashboardSvg from "../../../assets/dashboard.svg";
import taskSvg from "../../../assets/Task.svg";
import chatBoxSvg from "../../../assets/Chatbox.svg";
import passengerSvg from "../../../assets/passenger.svg";
import upcomingTrpSvg from "../../../assets/uncoming trip.svg";
import account from "../../../assets/account.svg";
import noteSvg from "../../../assets/notes.svg";
import moreSvg from "../../../assets/more.svg";

// const dashbaordIcon = (
//     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path
//             d="M9 3H4C3.44772 3 3 3.44772 3 4V11C3 11.5523 3.44772 12 4 12H9C9.55228 12 10 11.5523 10 11V4C10 3.44772 9.55228 3 9 3Z"
//             stroke="white"
//             strokeWidth="1.5"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//         />
//         <path
//             d="M20 3H15C14.4477 3 14 3.44772 14 4V7C14 7.55228 14.4477 8 15 8H20C20.5523 8 21 7.55228 21 7V4C21 3.44772 20.5523 3 20 3Z"
//             stroke="white"
//             strokeWidth="1.5"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//         />
//         <path
//             d="M20 12H15C14.4477 12 14 12.4477 14 13V20C14 20.5523 14.4477 21 15 21H20C20.5523 21 21 20.5523 21 20V13C21 12.4477 20.5523 12 20 12Z"
//             stroke="white"
//             strokeWidth="1.5"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//         />
//         <path
//             d="M9 16H4C3.44772 16 3 16.4477 3 17V20C3 20.5523 3.44772 21 4 21H9C9.55228 21 10 20.5523 10 20V17C10 16.4477 9.55228 16 9 16Z"
//             stroke="white"
//             strokeWidth="1.5"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//         />
//     </svg>
// );

function SideBarLayout() {
    const { sidebarOpen } = useSelector((state: RootState) => state.layout);
    const location = useLocation();
    const [selectedMenuKey, setSelectedMenuKey] = useState<string[]>([]);
    // const [selectedMenuKey, setSelectedMenuKey] = useState(() => {
    //     return JSON.parse(localStorage.getItem("selectedMenuKey") || '["2"]');
    // });

    // useEffect(() => {
    //     localStorage.setItem("selectedMenuKey", JSON.stringify(selectedMenuKey));
    // }, [selectedMenuKey]);

    useEffect(() => {
        const pathname = location.pathname;
        setSelectedMenuKey([pathname]);
    }, [location]);

    const menuList: MenuProps["items"] = [
        {
            key: "1",
            icon: sidebarOpen ? <img src={dashboardSvg} alt="Dashboard" /> : null,
            label: "Dashboard",
        },
        {
            key: "/inquiry",
            icon: sidebarOpen ? <img src={taskSvg} alt="Task" /> : null,
            label: <Link to="/inquiry">Task</Link>,
        },
        {
            key: "3",
            icon: sidebarOpen ? <img src={chatBoxSvg} alt="Chat box" /> : null,
            label: "Chat box",
        },
        {
            key: "/contacts",
            icon: sidebarOpen ? <img src={passengerSvg} alt="Passenger List" /> : null,
            label: <Link to="/contacts">Passenger List</Link>,
        },
        {
            key: "5",
            icon: sidebarOpen ? <img src={upcomingTrpSvg} alt="Upcoming Trip" /> : null,
            label: "Upcoming Trip",
        },
        {
            key: "6",
            icon: sidebarOpen ? <img src={account} alt="Dashboard" /> : null,
            label: "Account",
            children: [
                { key: "6-1", label: "Invoice" },
                { key: "6-2", label: "Purchase" },
                { key: "6-3", label: "Credit Note" },
                { key: "6-4", label: "Debit Note" },
                { key: "6-5", label: "JV" },
                { key: "6-6", label: "Payment" },
                { key: "6-7", label: "Recepit" },
                { key: "6-8", label: "Expense" },
                { key: "6-9", label: "Bank Account" },
                { key: "6-10", label: "Cash" },
                {
                    key: "6-11",
                    label: "Reports",
                    children: [
                        { key: "6-11-1", label: "Balance Sheet" },
                        { key: "6-11-2", label: "Profit and loss" },
                        { key: "6-11-3", label: "GstR 2A" },
                        { key: "6-11-4", label: "GstR 3B" },
                        { key: "6-11-5", label: "Creditor" },
                        { key: "6-11-6", label: "Debitor" },
                        {
                            key: "6-11-7",
                            label: "Outstanding report",
                            children: [
                                { key: "6-11-7-1", label: "Outstanding Debitor" },
                                { key: "6-11-7-2", label: "Outstanding Creditor" },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            key: "/notes",
            icon: sidebarOpen ? <img src={noteSvg} alt="Notes" /> : null,
            label: <Link to="/notes">Notes</Link>,
        },
        {
            key: "9",
            icon: sidebarOpen ? <img src={moreSvg} alt="More" /> : null,
            label: "More",
        },
    ];

    return (
        <div className={SiderCss.sidebar}>
            <Sider style={{ background: "transparent" }} collapsed={sidebarOpen}>
                <Flex align="center" className={`${sidebarOpen ? "paddingCollapsed" : "paddingExpanded"}`}>
                    <Image src={logo} preview={false} />
                    <Typography.Text className={`logoText ${sidebarOpen ? "hide" : "show"}`}>ATULAM</Typography.Text>
                </Flex>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={selectedMenuKey}
                    selectedKeys={selectedMenuKey}
                    style={{ borderRight: 0, backgroundColor: "transparent" }}
                    items={menuList}
                    className="sidebarMenu"
                    onSelect={function ({ selectedKeys }) {
                        setSelectedMenuKey(selectedKeys);
                    }}
                />
            </Sider>
        </div>
    );
}

export default SideBarLayout;
